import { Button } from "../ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { Transaction } from "../../graphql/codegen/graphql";
import { formatDecimalValue } from "../../utils/currency";
import { useTableScroll } from "../../hooks/use-table-scroll";
import DesoAvatar from "./deso-avatar";
import { Link } from "react-router-dom";

interface TableStakersProps {
  transactions: Array<Transaction>;
  total: number | null;
  offset: number;
  perPage: number;
  loadingPage: boolean;
  hideHeightColumn?: boolean;
  onPrevPage: () => void;
  onNextPage: () => void;
  hasPrevPage?: boolean;
  hasNextPage?: boolean;
}

const TableStakers = ({
  transactions,
  total,
  offset,
  perPage,
  loadingPage,
  hideHeightColumn,
  onPrevPage,
  onNextPage,
  hasPrevPage,
  hasNextPage,
}: TableStakersProps) => {
  const { wrapperElement, onPageChange } = useTableScroll();

  const headers = ["Username", "Total Stake"];

  if (!hideHeightColumn) {
    headers.unshift("#");
  }
  const iterateLeaderSchedule = Array(5).fill(null); // Replace null with actual data if available

  return (
    <div className="w-full" ref={wrapperElement}>
      <div className="relative rounded-2xl border overflow-hidden">
        <Table>
          <TableHeader>
            <TableRow>
              {headers.map((e) => (
                <TableHead key={e} className="last:text-right">
                  {e}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {iterateLeaderSchedule.map((_, index) => (
              <TableRow className="hover:bg-card dark:hover:bg-black/10 border-border-light">
                <TableCell className="align-middle p-1 px-4 text-xs whitespace-nowrap w-[50px]">
                  01
                </TableCell>
                <TableCell className="align-middle py-2 px-4 text-xs whitespace-nowrap">
                  <div className="flex items-start gap-3">
                    <DesoAvatar
                      size={40}
                      publicKey="BC1YLgi66tdjAaVfYpmM447cxsve3TpvfXD9h8X6JMak7gbKABoEVaT"
                      username="mossified"
                      clickable={true}
                      className="mb-1 cursor-pointer border w-10 h-10 hover:border-secondary"
                    />
                    <div className="flex flex-col items-start gap-1">
                      <div className=" flex items-center gap-2">
                        <div className="text-sm text-muted-foreground hover:underline underline-offset-4">
                          <Link to="../validator-detail">@mossified</Link>
                        </div>
                      </div>
                      <div className="text-xs text-muted font-mono">
                        BC1YLgi...fYpmM447
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell className="text-right">
                  <div className="flex flex-col items-end gap-1">
                    <div className="text-sm text-muted-foreground">
                      249,593.84{" "}
                      <img
                        src="/assets/img/logo-deso-mark.svg"
                        alt="DESO"
                        className="h-[14px] ml-2 inline-block"
                      />
                    </div>
                    <div className="text-xs text-muted font-mono">12.45%</div>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-xs text-muted">
          Showing {offset + 1}-{offset + transactions.length}
          {total !== null ? (
            <span> of {formatDecimalValue(total)} transactions</span>
          ) : (
            " transactions"
          )}
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              onPrevPage();
              onPageChange();
            }}
            disabled={hasPrevPage !== undefined ? !hasPrevPage : offset === 0}
            className={loadingPage ? "pointer-events-none cursor-default" : ""}
          >
            Previous
          </Button>

          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              onNextPage();
              onPageChange();
            }}
            disabled={
              hasNextPage !== undefined
                ? !hasNextPage
                : offset + perPage >= (total || 0)
            }
            className={loadingPage ? "pointer-events-none cursor-default" : ""}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TableStakers;
