import DesoAvatar from "@/components/shared/deso-avatar";
import { Button } from "../ui/button";
import {
  LucideCopy,
  LucideGlobe,
  LucideMail,
  LucideMapPin,
} from "lucide-react";
import { Badge } from "../ui/badge";
import { StakingModal } from "../validatorDetail/staking-modal";

const ValidatorCard = () => {
  return (
    <div className="rounded-3xl mb-12 bg-card">
      <div
        className="h-[260px] border-t dark:border-t-white/20 border-b border-b-background bg-black bg-no-repeat rounded-tl-3xl rounded-tr-3xl bg-cover bg-center"
        style={{ backgroundImage: "url('/assets/img/image-cover-2.png')" }}
      />

      <div className="flex flex-col-reverse gap-8 lg:gap-0 lg:flex-row justify-start items-start p-6 relative">
        <div className="flex flex-col lg:flex-row items-start gap-2">
          <div className="relative -top-[60px]">
            <div className="flex flex-col-reverse items-center justify-center gap-3">
              <DesoAvatar
                publicKey=""
                username="mossified"
                size={74}
                className="border-4 border-background bg-background"
                clickable={true}
              />
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="relative -mt-6 lg:mt-0">
              <div className="flex flex-col items-start">
                <h1 className="text-lg text-black dark:text-white font-medium flex items-center gap-2">
                  @Coinbase
                  <Badge variant={"outline"}>Rank #29</Badge>
                  <Badge
                    variant="destructive"
                    className="bg-red-600 text-white"
                  >
                    Jailed
                  </Badge>
                </h1>
              </div>
            </div>
            <div className="max-w-full md:max-w-[80%] mb-2">
              This is the official Validator run by Coinbase. Coinbase is an
              industry-leading cryptocurrency exchange and trading platform that
              serves over 300 million customers worldwide and partn...{" "}
              <a
                className="font-semibold underline underline-offset-4 text-sm cursor-pointer hover:text-muted-foreground"
                href="/"
              >
                Show More
              </a>
            </div>
            <div className="">
              <div className="flex flex-col md:flex-row md:items-center gap-2 md:gap-4 text-muted">
                <div className="flex items-center gap-2 text-sm">
                  <LucideGlobe className="w-5 h-5" />
                  <a
                    className="font-semibold underline underline-offset-4 cursor-pointer hover:text-muted-foreground"
                    href="/"
                    target="_blank"
                  >
                    coinbase.com
                  </a>
                </div>
                <div className="flex items-center gap-2 text-sm">
                  <LucideMapPin className="w-5 h-5" />
                  <span className="font-semibold">San Francisco, US</span>
                </div>
                <div className="flex items-center gap-2 text-sm">
                  {/* NOTE: There's a component with a copy-to-clipboard that we can re-use here */}
                  <LucideCopy className="w-5 h-5" />
                  <span className="font-mono">
                    <div className="hover:underline underline-offset-4 cursor-pointer">
                      BC1YLgi...fYpmM447
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-4 flex items-center gap-3">
              <StakingModal />
              <Button
                size="sm"
                variant="ghost"
                className="flex items-center gap-2"
              >
                <LucideMail className=" w-4 h-4" />
                Send Message
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidatorCard;
