import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { LucideInfo } from "lucide-react";

export function StakingModal() {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size="sm" variant="default">
          Stake $DESO
        </Button>
      </DialogTrigger>

      <DialogContent className="max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Stake $DESO with Coinbase</DialogTitle>
        </DialogHeader>

        <div className="flex flex-col items-start gap-4">
          <div className="flex justify-between items-center w-full">
            <Label htmlFor="stakingAmount">Your Stake Amount (DESO)</Label>
            <p className="text-xs">Your Unstaked Balance: 40.49 $DESO</p>
          </div>
          <Input type="text" id="stakingAmount" value="10.05" />
          <p className="text-xs">
            You are currently staking{" "}
            <strong className="text-muted-foreground">10.05 DESO</strong> with
            @Coinbase
          </p>
        </div>
        <div className="border-t border-border-light pt-4">
          <div className="flex items-center gap-2">
            <Button variant="default">Update Stake</Button>
            <Button variant="ghost">Unstake $DESO</Button>
          </div>
          <p className="text-xs mt-4 flex items-center gap-2">
            <LucideInfo className="w-4 h-4" />
            You can stake and unstake via this dashbaord every 3 epochs (~3
            hours)
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
}
