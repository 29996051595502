import { Metric } from "@/components/ui/metric";
import TableCoinHolders from "@/components/coinHolders/table-coin-holders";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  DesoBalancesDocument,
  DesoBalancesOrderBy,
  CoinHoldersDocument,
  TopHoldersFragment,
} from "../graphql/codegen/graphql";
import { client } from "../graphql/client";
import { useTitle } from "../hooks/use-title";
import { useContext, useEffect, useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { Skeleton } from "@/components/ui/skeleton";
import { calcPercentage, formatDecimalValue } from "../utils/currency";
import { MarketDataContext } from "../contexts/market-data";
import range from "lodash/range";

const ITEMS_PER_PAGE = 25;
const SKELETON_NUM_OF_CARDS = 3;

export const CoinHolders = () => {
  useTitle("Top $DESO Holders");

  const { toast } = useToast();

  const { marketData, loading: loadingMarketData } =
    useContext(MarketDataContext);

  const [fetchCoinHoldersLazy, { loading: loadingHolders }] = useLazyQuery(
    DesoBalancesDocument,
    {
      client,
    },
  );

  const [initLoading, setInitLoading] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [holders, setHolders] = useState<Array<TopHoldersFragment>>([]);
  const [holdersTotal, setHoldersTotal] = useState<number>(0);

  const fetchHolders = async () => {
    const { data } = await fetchCoinHoldersLazy({
      variables: {
        first: ITEMS_PER_PAGE,
        offset: offset,
        orderBy: DesoBalancesOrderBy.BalanceNanosDesc,
      },
    });

    return {
      holders: data?.desoBalances?.nodes.filter(
        (e) => e !== null,
      ) as Array<TopHoldersFragment>,
      total: data?.desoBalances?.totalCount || 0,
    };
  };

  useEffect(() => {
    const getHolders = async () => {
      try {
        const response = await fetchHolders();
        setHolders(response.holders);
        setHoldersTotal(response.total);
      } catch (e: any) {
        toast({
          variant: "destructive",
          title: "Error",
          description: `There was a problem getting top $DESO holders. ${JSON.stringify(
            e,
          )}`,
        });
      } finally {
        setInitLoading(false);
      }
    };

    getHolders();
  }, [offset, ITEMS_PER_PAGE]);

  return (
    <main className="container m-auto">
      <div className="text-center w-full md:w-[60%] m-auto my-12">
        <h1 className="text-4xl mb-4 text-black dark:text-white font-semibold">
          Top $DESO Holders
        </h1>
        <p className="mb-6 leading-7 xl:w-[70%] m-auto">
          With a total supply of 10,808,493 $DESO, already 97% is currently in
          circulation. See all of the top wallets {""}
          that hold $DESO in the table below.
        </p>
      </div>

      <div className="mb-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Top Row */}
          {initLoading || loadingMarketData || !marketData ? (
            range(SKELETON_NUM_OF_CARDS).map(() => (
              <Skeleton className="h-[80px] w-full" />
            ))
          ) : (
            <>
              <Metric
                value={formatDecimalValue(holdersTotal)}
                label="Total Holders"
              />

              <Metric
                value={formatDecimalValue(marketData.total_supply, 0)}
                label="Total Supply"
                caption="DESO"
                tooltip={`The circulating supply of DESO is ${formatDecimalValue(
                  marketData.circulating_supply,
                  0,
                )} (${calcPercentage(
                  marketData.circulating_supply,
                  marketData.total_supply,
                )}%)`}
              />

              <Metric
                value={formatDecimalValue(marketData.circulating_supply, 0)}
                label="Circulating Supply"
                caption="DESO"
                tooltip="The supply of $DESO in circulation"
              />
            </>
          )}
        </div>
      </div>

      <div className="mb-12">
        <div className="flex items-center">
          {initLoading ? (
            <div className="overflow-hidden rounded-xl w-full">
              <Skeleton className="h-[48px] w-full rounded-none mb-1" />
              <Skeleton className="h-[300px] w-full rounded-none" />
            </div>
          ) : (
            <TableCoinHolders
              holders={holders}
              total={holdersTotal}
              loadingPage={loadingHolders}
              offset={offset}
              perPage={ITEMS_PER_PAGE}
              onPrevPage={() => {
                setOffset((prev) => prev - ITEMS_PER_PAGE);
              }}
              onNextPage={() => {
                setOffset((prev) => prev + ITEMS_PER_PAGE);
              }}
            />
          )}
        </div>
      </div>
    </main>
  );
};
