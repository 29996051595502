import { Button } from "@/components/ui/button";
import {
  NetworkIcon,
  PlusIcon,
  RocketIcon,
  SettingsIcon,
  XIcon,
} from "lucide-react";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { InfoTooltip } from "@/components/shared/info-tooltip";

const ValidatorSettingsPage = () => {
  return (
    <main className="mt-4 container m-auto">
      <div className="w-full justify-between flex items-center">
        <h1 className="text-2xl text-black dark:text-white font-semibold">
          Validator Management
        </h1>
      </div>
      <div className="mt-8">
        <div className="flex justify-between items-center">
          <h3 className="mb-4 flex items-center">
            <NetworkIcon className="mr-2" />
            Domain Management
          </h3>
        </div>
        <div className="border border-border rounded-2xl font-mono text-sm">
          <div className="p-4 border-b border-border-light">
            <div className="flex md:items-center flex-col md:flex-row gap-4 md:gap-0">
              <h3 className="text-muted-foreground w-full md:max-w-[170px]">
                Add Domain
              </h3>
              <div className="flex md:items-center gap-4 md:gap-2 flex-col md:flex-row items-start">
                <Input
                  type="text"
                  placeholder="https://..."
                  className="w-full md:w-[400px] rounded-full text-muted-foreground"
                />
                <Button variant="outline" className="flex items-center gap-2">
                  <PlusIcon className="w-4 h-4" />
                  Add Domain
                </Button>
              </div>
            </div>
          </div>
          <div className="p-4 border-b border-border-light">
            <div className="flex items-start md:items-center flex-col md:flex-row w-full gap-2 md:gap-0">
              <h3 className="text-muted-foreground w-full max-w-[170px]">
                Domain #1
              </h3>
              <div className="flex flex-col md:flex-row gap-4 md:gap-0 items-start md:items-center justify-between w-full">
                https://validators.coinbase.com/?blockchain="DESO"
                <Button
                  size="sm"
                  variant="outline"
                  className="flex items-center gap-2"
                >
                  <XIcon className="w-4 h-4" />
                  Remove
                </Button>
              </div>
            </div>
          </div>
          <div className="p-4">
            <div className="flex items-start md:items-center flex-col md:flex-row w-full gap-2 md:gap-0">
              <h3 className="text-muted-foreground w-full max-w-[170px]">
                Domain #2
              </h3>
              <div className="flex flex-col md:flex-row gap-4 md:gap-0 items-start md:items-center justify-between w-full">
                https://validators-external-2.coinbase.com/?blockchain="DESO"
                <Button
                  size="sm"
                  variant="outline"
                  className="flex items-center gap-2"
                >
                  <XIcon className="w-4 h-4" />
                  Remove
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8">
        <div className="flex justify-between items-center">
          <h3 className="mb-4 flex items-center">
            <SettingsIcon className="mr-2" />
            Validator Settings
          </h3>
        </div>
        <div className="border border-border rounded-2xl font-mono text-sm">
          <div className="p-4 border-b border-border-light">
            <div className="flex items-start md:items-center flex-col md:flex-row w-full gap-4 md:gap-0">
              <h3 className="text-muted-foreground w-full max-w-[170px]">
                Delegated Stake
              </h3>
              <div className="flex items-center gap-2">
                <div className="items-top flex space-x-3">
                  <Checkbox id="disable-delegated-stake" />
                  <div className="grid gap-1.5 leading-none">
                    <label
                      htmlFor="disable-delegated-stake"
                      className="text-sm font-medium leading-none text-muted-foreground"
                    >
                      Enable Delegated Stake
                    </label>
                    <p className="text-sm text-muted">
                      This only applies for validators with delegated stake.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 border-b border-border-light">
            <div className="flex items-start md:items-center flex-col md:flex-row w-full gap-4 md:gap-0">
              <h3 className="text-muted-foreground w-full max-w-[170px]">
                Commission Rate %
              </h3>
              <div className="flex items-center justify-between w-full">
                <Input
                  type="text"
                  placeholder="0.00%"
                  className="w-[100px] rounded-full text-muted-foreground"
                />
              </div>
            </div>
          </div>
          <div className="p-4 border-b border-border-light">
            <div className="flex items-start md:items-center flex-col md:flex-row w-full gap-4 md:gap-0">
              <h3 className="text-muted-foreground w-full max-w-[170px] flex items-center gap-2">
                Voting Public Key
              </h3>
              <div className="flex items-center justify-between w-full">
                <Input
                  type="text"
                  placeholder="Input Public Key"
                  className="w-[400px] rounded-full text-muted-foreground"
                />
              </div>
            </div>
          </div>
          <div className="p-4">
            <div className="flex items-start md:items-center flex-col md:flex-row w-full gap-4 md:gap-0">
              <h3 className="text-muted-foreground w-full max-w-[170px] flex items-center gap-2">
                Voting Authorization
              </h3>
              <div className="flex items-center justify-between w-full">
                <Input
                  type="text"
                  placeholder="Input Voting Authorization"
                  className="w-[400px] rounded-full text-muted-foreground"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-8">
        <div className="flex justify-between items-center">
          <h3 className="mb-4 flex items-center">
            <RocketIcon className="mr-2" />
            Authorize
          </h3>
        </div>
        <div className="flex flex-col md:flex-row md:items-center gap-4">
          <Button variant={"default"}>Register Validator</Button>
          <Button variant={"outline"}>Unregister Validator</Button>
          <Button variant={"outline"}>Unjail Validator</Button>
        </div>
      </div>
    </main>
  );
};

export default ValidatorSettingsPage;
