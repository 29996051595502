import { Button } from "@/components/ui/button";
import { ArrowLeftCircleIcon, ExternalLinkIcon, User } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { setTitle } from "../hooks/use-title";
import { useLazyQuery } from "@apollo/client";
import {
  AccountDetailsFragment,
  AccountsFilteredDocument,
} from "../graphql/codegen/graphql";
import { client } from "../graphql/client";
import UserNetworkActivity from "@/components/userDetail/user-network-activity";
import ProfileCard from "@/components/userDetail/profile-card";
import HolderLeaderboards from "@/components/userDetail/holder-leaderboards";
import UserSocialAcitivity from "@/components/userDetail/user-social-activity";
import RecentTransactions from "@/components/home/recent-transactions";
import UserEarnings from "@/components/userDetail/user-earnings";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import CopyToClipboard from "@/components/shared/copy-to-clipboard";
import { Skeleton } from "@/components/ui/skeleton";
import { useEffect, useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { formatDisplayName, isDesoPublicKey } from "../utils/helpers";

const UserDetail = () => {
  const { toast } = useToast();

  const { userId } = useParams();
  const navigate = useNavigate();

  const [userAccount, setUserAccount] = useState<AccountDetailsFragment | null>(
    null,
  );

  const [fetchUserLazy, { loading: loadingUser }] = useLazyQuery(
    AccountsFilteredDocument,
    {
      client,
    },
  );

  const onError = (e: string) => {
    toast({
      variant: "destructive",
      title: "Error",
      description: `There was a problem getting user. ${JSON.stringify(e)}`,
    });
  };

  useEffect(() => {
    const getUser = async () => {
      if (!userId) {
        onError("Please make sure that the URL is correct");
        return;
      }

      const filter = isDesoPublicKey(userId)
        ? { publicKey: { equalTo: userId } }
        : {
            username: {
              equalToInsensitive: userId,
            },
          };

      const user = await fetchUserLazy({
        variables: {
          filter,
        },
      }).then((e) => e.data?.accounts?.nodes?.[0] || null);

      setUserAccount(user);
    };

    getUser();
  }, [userId]);

  useEffect(() => {
    if (userAccount?.publicKey) {
      setTitle(
        `${formatDisplayName(
          userAccount.username,
          userAccount.publicKey,
          true,
        )} Activity`,
      );
    }
  }, [userAccount]);

  const pubKey =
    (isDesoPublicKey(userId || "") ? userId : userAccount?.publicKey) || "";

  return (
    <main className="mt-4 container m-auto">
      <div className="flex items-center justify-between mb-5">
        <Button
          variant="outline"
          size="sm"
          className="rounded-full"
          onClick={() => navigate(-1)}
        >
          <ArrowLeftCircleIcon className="mr-2 w-5 h-5" />
          Go Back
        </Button>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="sm" className="rounded-full">
              <ExternalLinkIcon className="mr-2 w-5 h-5" />
              Share
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent className="w-56" align="end">
            <DropdownMenuGroup>
              <DropdownMenuItem className="p-0">
                <CopyToClipboard
                  text={window.location.href}
                  className="w-full px-2 py-1.5"
                  showIcon={false}
                  showToast={true}
                  toastText={
                    "The link to this profile has been successfully copied to your clipboard"
                  }
                >
                  <User className="mr-2 h-4 w-4" />
                  <span>Copy Link</span>
                </CopyToClipboard>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {loadingUser ? (
        <div className="rounded-3xl overflow-hidden">
          <Skeleton className="w-full h-[260px] mb-1" />
          <Skeleton className="w-full h-[180px] mb-1" />
          <Skeleton className="w-full h-[50px]" />
        </div>
      ) : (
        <>
          <ProfileCard user={userAccount} publicKey={pubKey} />

          <UserNetworkActivity publicKey={pubKey} />

          {userAccount && <UserSocialAcitivity user={userAccount} />}

          <UserEarnings publicKey={pubKey} />

          {userAccount && <HolderLeaderboards user={userAccount} />}

          <RecentTransactions
            publicKey={pubKey}
            viewAllLink={`/txn?transactors=${pubKey}`}
          />
        </>
      )}
    </main>
  );
};

export default UserDetail;
