import { Transaction } from "../../graphql/codegen/graphql";
import maxBy from "lodash/maxBy";
import range from "lodash/range";
import { cn } from "@/lib";
import { TRANSACTION_TYPE, TRANSACTION_TYPES_BY_INDEX } from "../../types";
import { DiamondPostLink } from "./diamond-post-link";
import { DiamondNFTLink } from "./diamond-nft-link";
import { desoNanosToDeso } from "../../utils/currency";
import { Gem } from "lucide-react";
import {
  formatTxnUsername,
  publicKeyByteArrayToBase58Check,
} from "../../utils/helpers";
import { SocialCard } from "@/components/shared/social-card";

export interface AffectedPublicKey {
  publicKey: string;
  __typename: string;
}

export interface BasicTransferOutput {
  amount_nanos: string;
  public_key: string;
}

enum AssociationReactionValue {
  LIKE = "👍",
  DISLIKE = "👎",
  LOVE = "❤️",
  LAUGH = "😂",
  ASTONISHED = "😲",
  SAD = "😥",
  ANGRY = "😠",
}

interface ActivityActionProps {
  item: Transaction;
  wrap?: boolean;
}

const isZeroPublicKey = (publicKey: Array<number>) => {
  return publicKey.every((e) => e === 0);
};

export const TxnActionItem = ({ item, wrap }: ActivityActionProps) => {
  const userKey = item.publicKey;

  const formatMessage = (
    performerUserKey?: string | null,
    action?: string | JSX.Element,
    receiverUserKey?: string | null,
    subAction?: string | JSX.Element,
  ) => {
    return (
      <div className={cn("flex", wrap && "flex-wrap")}>
        {performerUserKey && (
          <SocialCard showAvatar={true} publicKey={performerUserKey} />
        )}

        {action && (
          <span
            className={`flex items-center whitespace-nowrap leading-8 ${cn(
              !!performerUserKey && "ml-2",
              !!receiverUserKey && "mr-2",
            )}`}
          >
            {action}
          </span>
        )}

        {receiverUserKey && (
          <SocialCard showAvatar={true} publicKey={receiverUserKey} />
        )}

        {subAction && (
          <span className={"whitespace-nowrap leading-8"}>{subAction}</span>
        )}
      </div>
    );
  };

  const parsePostAssociation = (rawTxn: any) => {
    if (
      !rawTxn ||
      !rawTxn.AssociationType ||
      !rawTxn.AssociationValue ||
      !rawTxn.PostHashHex
    ) {
      return {
        type: "",
        value: "a post association",
        postId: "",
      };
    }

    const associationType = rawTxn.AssociationType;
    const associationValue = rawTxn.AssociationValue;
    const postId = rawTxn.PostHashHex;

    const formattedValue =
      associationType === "REACTION" &&
      (AssociationReactionValue as any)[associationValue]
        ? (AssociationReactionValue as any)[associationValue]
        : associationValue;

    return {
      postId,
      type: associationType.replaceAll("_", " ").toLowerCase(),
      value: formattedValue,
    };
  };

  if (!item.txnType) {
    return <>Transaction type is missing</>;
  }

  switch (TRANSACTION_TYPES_BY_INDEX[item.txnType]) {
    case TRANSACTION_TYPE.TxnTypeCreateUserAssociation: {
      /**** TESTED ****/
      const { TargetUserPublicKeyBase58Check } = item.txIndexMetadata;

      return formatMessage(
        userKey,
        TargetUserPublicKeyBase58Check
          ? "created an association with"
          : "created an association with another user",
        TargetUserPublicKeyBase58Check,
      );
    }
    case TRANSACTION_TYPE.TxnTypeDeleteUserAssociation: {
      /**** TESTED ****/
      const { TargetUserPublicKeyBase58Check } = item.txIndexMetadata;

      return formatMessage(
        userKey,
        TargetUserPublicKeyBase58Check
          ? "deleted an association with"
          : "deleted an association with another user",
        TargetUserPublicKeyBase58Check,
      );
    }
    case TRANSACTION_TYPE.TxnTypeCreatePostAssociation: {
      /**** TESTED ****/
      const { postId, type, value } = parsePostAssociation(
        item?.txIndexMetadata,
      );

      if (
        item?.txIndexMetadata?.AssociationType === "REACTION" ||
        item?.txIndexMetadata?.AssociationType === "POLL_RESPONSE"
      ) {
        return formatMessage(
          userKey,
          <>
            <span className="mx-2">{value}</span>
            {type}
            {postId && (
              <>
                &nbsp;to a&nbsp;
                <DiamondPostLink postId={postId} />
              </>
            )}
          </>,
        );
      }

      return formatMessage(
        userKey,
        postId ? (
          <>
            created an association to a&nbsp;
            <DiamondPostLink postId={postId} />
          </>
        ) : (
          "created an association to a post"
        ),
      );
    }
    case TRANSACTION_TYPE.TxnTypeDeletePostAssociation: {
      /**** TESTED ****/
      const { postId, type, value } = parsePostAssociation(
        item?.txIndexMetadata,
      );

      if (
        item?.txIndexMetadata?.AssociationType === "REACTION" ||
        item?.txIndexMetadata?.AssociationType === "POLL_RESPONSE"
      ) {
        return formatMessage(
          userKey,
          <>
            deleted <span className="mx-2">{value}</span>
            {type}
            {postId && (
              <>
                &nbsp;from a&nbsp;
                <DiamondPostLink postId={postId} />
              </>
            )}
          </>,
        );
      }

      return formatMessage(
        userKey,
        postId ? (
          <>
            deleted an association from a&nbsp;
            <DiamondPostLink postId={postId} />
          </>
        ) : (
          "deleted an association from a post"
        ),
      );
    }
    case TRANSACTION_TYPE.TxnTypeLike: {
      /**** TESTED ****/
      return formatMessage(
        userKey,
        <>
          liked a&nbsp;
          <DiamondPostLink postId={item.txIndexMetadata?.PostHashHex || ""} />
        </>,
      );
    }
    case TRANSACTION_TYPE.TxnTypeBasicTransfer: {
      /**** TESTED ****/
      const outputs = (item.outputs || []) as Array<BasicTransferOutput>;
      const receiver = maxBy(
        outputs.filter((o) => o.public_key !== item.publicKey),
        "amount_nanos",
      );
      const amountFormatted = desoNanosToDeso(receiver?.amount_nanos as string);
      const diamondsSent = item.txIndexMetadata?.DiamondLevel || 0;

      if (diamondsSent) {
        return formatMessage(
          userKey,
          <>
            sent&nbsp;
            <div className="flex">
              {range(diamondsSent).map((i) => (
                <Gem size={16} key={i} />
              ))}
            </div>
            &nbsp;to
          </>,
          receiver?.public_key,
          <>
            &nbsp;on a&nbsp;
            <DiamondPostLink postId={item.txIndexMetadata?.PostHashHex || ""} />
          </>,
        );
      }

      return formatMessage(
        userKey,
        <>
          sent <div className="text-green-600 mx-1">{amountFormatted}</div>{" "}
          $DESO to{" "}
        </>,
        receiver?.public_key,
      );
    }
    case TRANSACTION_TYPE.TxnTypeBitcoinExchange: {
      return formatMessage(userKey, "burned BTC for DESO");
    }
    case TRANSACTION_TYPE.TxnTypeNewMessage: {
      /**** TESTED ****/
      const isDM = item.txnMeta.NewMessageType === 0;
      const isCreation = item.txnMeta.NewMessageOperation === 0;

      const sender = publicKeyByteArrayToBase58Check(
        item.txnMeta.SenderAccessGroupOwnerPublicKey,
      );
      const receiver = publicKeyByteArrayToBase58Check(
        item.txnMeta.RecipientAccessGroupOwnerPublicKey,
      );

      const subAction = !isDM ? (
        <>
          's group called{" "}
          <b>
            {String.fromCharCode(
              ...item.txnMeta.RecipientAccessGroupKeyName.filter(
                (e: number) => e !== 0, // remove trailing zeros
              ),
            )}
          </b>
        </>
      ) : (
        ""
      );

      return formatMessage(
        sender,
        `${isCreation ? "sent" : "updated"} a message to`,
        receiver,
        subAction,
      );
    }
    case TRANSACTION_TYPE.TxnTypePrivateMessage: {
      /**** TESTED ****/
      const receiver = formatTxnUsername(item.txnMeta.RecipientPublicKey);
      return formatMessage(userKey, "sent a message to", receiver);
    }
    case TRANSACTION_TYPE.TxnTypeUpdateProfile: {
      /**** TESTED ****/
      return formatMessage(userKey, "updated a profile");
    }
    case TRANSACTION_TYPE.TxnTypeSubmitPost: {
      /**** TESTED ****/
      let action: JSX.Element | string = "";

      const isRepost = !!item.extraData.RecloutedPostHash;
      const postId = item.txnMeta?.PostHashToModify
        ? item.txIndexMetadata?.PostHashBeingModifiedHex
        : item.transactionHash;

      if (isRepost) {
        action = (
          <>
            reposted a&nbsp;
            <DiamondPostLink postId={postId} />
          </>
        );
      } else if (item.txIndexMetadata?.ParentPostHashHex) {
        action = (
          <>
            commented on a&nbsp;
            <DiamondPostLink postId={postId} />
          </>
        );
      } else if (item.txIndexMetadata?.PostHashToModify) {
        action = (
          <>
            edited a&nbsp;
            <DiamondPostLink postId={postId} />
          </>
        );
      } else {
        action = (
          <>
            submitted a&nbsp;
            <DiamondPostLink postId={postId} />
          </>
        );
      }

      return formatMessage(userKey, action);
    }
    case TRANSACTION_TYPE.TxnTypeFollow: {
      /**** TESTED ****/
      return formatMessage(
        userKey,
        item.txnMeta.IsUnfollow ? "unfollowed" : "followed",
        formatTxnUsername(item.txnMeta.FollowedPublicKey),
      );
    }
    case TRANSACTION_TYPE.TxnTypeCreatorCoin: {
      /**** TESTED ****/
      const operation =
        item.txIndexMetadata?.OperationType === "buy" ? "bought" : "sold";
      const creator = formatTxnUsername(item.txnMeta.ProfilePublicKey);

      return formatMessage(userKey, operation, creator, "'s creator coin");
    }
    case TRANSACTION_TYPE.TxnTypeSwapIdentity: {
      /**** TESTED ****/
      return formatMessage(userKey, "swapped identity");
    }
    case TRANSACTION_TYPE.TxnTypeUpdateGlobalParams: {
      /**** TESTED ****/
      return formatMessage(userKey, "updated global params");
    }
    case TRANSACTION_TYPE.TxnTypeCreateNFT: {
      /**** TESTED ****/
      return formatMessage(
        userKey,
        <>
          created an&nbsp;
          <DiamondNFTLink NFTId={item.txIndexMetadata?.NFTPostHashHex || ""} />
        </>,
      );
    }
    case TRANSACTION_TYPE.TxnTypeUpdateNFT: {
      /**** TESTED ****/
      let action: JSX.Element;

      if (item.txnMeta?.IsForSale) {
        action = (
          <>
            put an&nbsp;
            <DiamondNFTLink NFTId={item.txIndexMetadata?.NFTPostHashHex} />{" "}
            &nbsp;on sale
          </>
        );
      } else {
        action = (
          <>
            took &nbsp;
            <DiamondNFTLink
              NFTId={item.txIndexMetadata?.NFTPostHashHex || ""}
            />{" "}
            &nbsp;off market
          </>
        );
      }

      return formatMessage(userKey, action);
    }
    case TRANSACTION_TYPE.TxnTypeAcceptNFTBid: {
      /**** TESTED ****/
      return formatMessage(
        userKey,
        <>
          sold&nbsp;
          <DiamondNFTLink NFTId={item.txIndexMetadata?.NFTPostHashHex || ""} />
        </>,
      );
    }
    case TRANSACTION_TYPE.TxnTypeNFTBid: {
      /**** TESTED ****/
      return formatMessage(
        userKey,
        <>
          bid on an&nbsp;
          <DiamondNFTLink NFTId={item.txIndexMetadata?.NFTPostHashHex || ""} />
        </>,
      );
    }
    case TRANSACTION_TYPE.TxnTypeNFTTransfer: {
      /**** TESTED ****/
      return formatMessage(
        userKey,
        <>
          bid on an&nbsp;
          <DiamondNFTLink NFTId={item.txIndexMetadata?.NFTPostHashHex || ""} />
        </>,
      );
    }
    case TRANSACTION_TYPE.TxnTypeAcceptNFTTransfer:
      /**** TESTED ****/
      return formatMessage(
        userKey,
        <>
          accepted an&nbsp;
          <DiamondNFTLink NFTId={item.txIndexMetadata?.NFTPostHashHex || ""} />
          &nbsp;transfer
        </>,
      );
    case TRANSACTION_TYPE.TxnTypeBurnNFT:
      /**** TESTED ****/
      return formatMessage(
        userKey,
        <>
          burned an&nbsp;
          <DiamondNFTLink NFTId={item.txIndexMetadata?.NFTPostHashHex || ""} />
        </>,
      );
    case TRANSACTION_TYPE.TxnTypeAuthorizeDerivedKey: {
      /**** TESTED ****/
      return formatMessage(userKey, "authorized a derived key");
    }
    case TRANSACTION_TYPE.TxnTypeDAOCoinTransfer: {
      /**** TESTED ****/
      const daoReceiver = formatTxnUsername(item.txnMeta.ReceiverPublicKey);
      return formatMessage(userKey, "transferred token to", daoReceiver);
    }
    case TRANSACTION_TYPE.TxnTypeDAOCoinLimitOrder: {
      /**** TESTED ****/
      const isCancelling = !!item.txnMeta.CancelOrderID;

      if (isCancelling) {
        return formatMessage(userKey, "cancelled an order");
      }

      const buyingPublicKey = isZeroPublicKey(
        item.txnMeta.BuyingDAOCoinCreatorPublicKey,
      )
        ? null
        : item.txIndexMetadata?.BuyingDAOCoinCreatorPublicKey;

      const sellingPublicKey = isZeroPublicKey(
        item.txnMeta.SellingDAOCoinCreatorPublicKey,
      )
        ? null
        : item.txIndexMetadata?.SellingDAOCoinCreatorPublicKey;

      return formatMessage(
        userKey,
        <>
          submitted an order to buy{" "}
          {buyingPublicKey ? (
            <div className="mx-2">
              <SocialCard showAvatar={true} publicKey={buyingPublicKey} />
            </div>
          ) : (
            "$DESO"
          )}{" "}
          and sell{" "}
          {sellingPublicKey ? (
            <div className="mx-2">
              <SocialCard showAvatar={true} publicKey={sellingPublicKey} />
            </div>
          ) : (
            "$DESO"
          )}
        </>,
      );
    }
    case TRANSACTION_TYPE.TxnTypeCreatorCoinTransfer: {
      /**** TESTED ****/
      return formatMessage(
        userKey,
        <>
          transferred
          <div className="mx-2">
            <SocialCard
              showAvatar={true}
              publicKey={formatTxnUsername(item.txnMeta.ProfilePublicKey)}
            />
          </div>
          creator coin to
        </>,
        formatTxnUsername(item.txnMeta.ReceiverPublicKey),
      );
    }
    case TRANSACTION_TYPE.TxnTypeBlockReward: {
      /**** TESTED ****/
      const receiver = item.affectedPublicKeys.nodes[0]?.publicKey || "";
      return formatMessage(receiver, "received a block reward");
    }
    case TRANSACTION_TYPE.TxnTypeMessagingGroup: {
      /**** TESTED ****/
      return formatMessage(userKey, "performed a messaging group operation");
    }
    case TRANSACTION_TYPE.TxnTypeAccessGroup: {
      /**** TESTED ****/
      return formatMessage(userKey, "performed an access group operation");
    }
    case TRANSACTION_TYPE.TxnTypeDAOCoin: {
      /**** TESTED ****/
      return formatMessage(userKey, "performed a DeSo Token operation");
    }
    case TRANSACTION_TYPE.TxnTypeAccessGroupMembers:
      const actionByTypes = {
        "2": "added members to",
        "3": "removed members from",
        "4": "updated members in",
      };

      const action =
        (actionByTypes as any)?.[
          item.txIndexMetadata.AccessGroupMemberOperationType.toString()
        ] || "performed an action with members in";

      const groupName = atob(item.txnMeta.AccessGroupKeyName);

      return formatMessage(
        userKey,
        <>
          {action} an access group <b className="ml-1">{groupName}</b>
        </>,
      );
    case TRANSACTION_TYPE.TxnTypeUpdateBitcoinUSDExchangeRate:
    default:
      return <span>Unhandled transaction</span>;
  }
};
