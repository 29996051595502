import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Header } from "./components/shared/header";
import {
  ActiveAccountContext,
  ActiveAccountContextType,
} from "./contexts/active-account";
import { client } from "./graphql/client";
import { AccountByPublicKeyDocument } from "./graphql/codegen/graphql";
import { useDesoIdentity } from "./hooks/use-deso-identity";
import { Outlet } from "react-router";
import SubHeader from "@/components/shared/sub-header";
import { Toaster } from "./components/ui/toaster";
import {
  MarketDataContext,
  MarketDataContextType,
} from "./contexts/market-data";
import { getDesoMarketData } from "./utils/coin-gecko";
import { toast } from "./components/ui/use-toast";
import Spinner from "@/components/shared/spinner";
import { ThemeProvider } from "./contexts/theme";
import ScrollToTop from "./components/shared/scroll-to-top";

function App() {
  const { currentUser, pendingDerivedKeyAuthorization } = useDesoIdentity();
  const [getAccountByPublicKey, { loading }] = useLazyQuery(
    AccountByPublicKeyDocument,
    {
      client,
    },
  );

  const [activeAccountState, setActiveAccountState] =
    useState<ActiveAccountContextType>({
      account: null,
      accountLoading: false,
    });

  const [marketDataState, setMarketDataState] = useState<MarketDataContextType>(
    {
      marketData: null,
      exchangeRate: 0,
      loading: false,
    },
  );

  useEffect(() => {
    setActiveAccountState((prev) => ({
      ...prev,
      accountLoading: pendingDerivedKeyAuthorization || loading,
    }));
  }, [pendingDerivedKeyAuthorization, loading]);

  useEffect(() => {
    if (!currentUser?.publicKey) {
      setActiveAccountState((prev) => ({
        ...prev,
        account: null,
      }));
      return;
    }

    getAccountByPublicKey({
      variables: { publicKey: currentUser.publicKey },
    })
      .then((res) => {
        const account = res.data?.accountByPublicKey ?? null;
        // If this is a new user, they may not have any transactions in the DB
        // yet. In that case, the api will return `null` and we'll just use the
        // identity user info to create an ephemeral client side user.
        setActiveAccountState((prev) => ({
          ...prev,
          account: account ?? {
            id: currentUser.publicKey,
            publicKey: currentUser.publicKey,
          },
        }));
      })
      .catch((e) => {
        // TODO: UI error feedback if this fails...
        setActiveAccountState((prev) => ({
          ...prev,
          account: null,
        }));
      });
  }, [currentUser?.publicKey, getAccountByPublicKey]);

  useEffect(() => {
    const fetchMarketData = async () => {
      setMarketDataState({
        marketData: null,
        exchangeRate: 0,
        loading: true,
      });

      try {
        const { market_data } = await getDesoMarketData();
        setMarketDataState({
          marketData: market_data,
          exchangeRate: market_data.current_price.usd * 100 || 0,
          loading: false,
        });
      } catch (e: any) {
        toast({
          variant: "destructive",
          title: "Error",
          description: `There was a problem accessing CoinGecko API. ${JSON.stringify(
            e,
          )}`,
        });

        setMarketDataState({
          marketData: null,
          exchangeRate: 0,
          loading: false,
        });
      }
    };

    fetchMarketData();
  }, []);

  return (
    <ThemeProvider>
      <ActiveAccountContext.Provider value={activeAccountState}>
        <MarketDataContext.Provider value={marketDataState}>
          <ScrollToTop>
            <main className="min-h-screen">
              <Header />

              <SubHeader />

              <section className="p-4 md:p-6">
                {activeAccountState.accountLoading ? (
                  <div className="flex justify-center my-8 min-h-[100vh]">
                    <Spinner size={48} />
                  </div>
                ) : (
                  <Outlet />
                )}
              </section>
            </main>
          </ScrollToTop>

          <Toaster />
        </MarketDataContext.Provider>
      </ActiveAccountContext.Provider>
    </ThemeProvider>
  );
}

export default App;
