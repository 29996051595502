import DesoAvatar from "@/components/shared/deso-avatar";
import { SocialCard } from "@/components/shared/social-card";
import TableValidators from "@/components/shared/table-validators";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Metric } from "@/components/ui/metric";
import {
  AtomIcon,
  FlagIcon,
  Loader2Icon,
  ScrollTextIcon,
  TrophyIcon,
  User2Icon,
} from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Link } from "react-router-dom";

const ValidatorPage = () => {
  const iterateLeaderSchedule = Array(5).fill(null); // Replace null with actual data if available
  const iterateCurrentStakers = Array(3).fill(null); // Replace null with actual data if available
  return (
    <main className="mt-4 container m-auto">
      <div className="w-full justify-between flex items-center">
        <h1 className="text-2xl text-black dark:text-white font-semibold">
          Validators
        </h1>
        <div className="flex items-center gap-2">
          <Button
            variant="link"
            className="text-foreground flex items-center gap-2"
          >
            <ScrollTextIcon className="w-4 h-4" />
            Documentation
          </Button>
          <Link to={"/validator-settings"}>
            <Button variant="default">Run a Validator</Button>
          </Link>
        </div>
      </div>
      <div className="mt-8">
        <div className="flex justify-between items-center">
          <h3 className="mb-4 flex items-center">
            <AtomIcon className="mr-2" />
            Staking Overview
          </h3>
        </div>
        <div className="grid align-center grid-cols-1 md:grid-cols-2 xl:grid-cols-4 w-full gap-4">
          <div className="flex flex-col gap-2">
            <Metric
              value="3,485,492"
              caption="DESO"
              label="Total Amount Staked"
            />
          </div>
          <div className="flex flex-col gap-2">
            <Metric value="100,495" label="Unique Wallets Staking" />
          </div>
          <div className="flex flex-col gap-2">
            <Metric value="19.58%" label="Average APY%" />
          </div>
          <div className="flex flex-col gap-2">
            <Metric
              value="1,049"
              label="Total Validators"
              caption="34 Countries"
            />
          </div>
        </div>
      </div>
      <div className="mt-8 pb-8 border-b border-border-light">
        <div className="flex justify-between items-center">
          <h3 className="mb-4 flex items-center">
            <User2Icon className="mr-2" />
            My Stake Entries
          </h3>
        </div>
        <div className="grid align-center grid-cols-1 md:grid-cols-3 w-full gap-4">
          <div className="flex flex-col gap-2">
            <Metric value="1,592.56" caption="DESO" label="My Staked Balance" />
          </div>
          <div className="flex flex-col gap-2">
            <Metric value="55.25" label="My Unstaked Balance" caption="DESO" />
          </div>
          <div className="flex flex-col gap-2">
            <Metric
              value="19.58%"
              caption="$29,825 USD"
              label="Lifetime Earnings"
            />
          </div>
        </div>
        <div className="mt-4 text-sm">
          <div className="mb-2 text-xs text-muted">
            You are currently staking with 3 validators:
          </div>
          <div className="flex items-stretch gap-2 flex-wrap">
            {iterateCurrentStakers.map((_, index) => (
              <div
                className="items-center bg-card py-2 px-4 rounded-full flex gap-2"
                key={index}
              >
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Loader2Icon className="animate-spin h-5 w-5" />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Stake unlocks in 01:49 hours</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <DesoAvatar
                  size={20}
                  publicKey="BC1YLgi66tdjAaVfYpmM447cxsve3TpvfXD9h8X6JMak7gbKABoEVaT"
                  username="mossified"
                  clickable={true}
                  className="cursor-pointer border w-10 h-10 hover:border-secondary"
                />
                <span className="text-muted-foreground text-xs">
                  @mossified
                </span>{" "}
                <span className="font-mono text-xs text-muted flex items-center gap-2">
                  23.5{" "}
                  <img
                    src="/assets/img/logo-deso-mark.svg"
                    alt="DESO"
                    className="h-[12px] inline-block"
                  />
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center pt-6">
        <h3 className="mb-4 flex items-center">
          <TrophyIcon className="mr-2" />
          Leader Schedule
        </h3>
        <div className="">
          <h3 className="mb-4 flex items-center gap-2">
            <div className="flex flex-col gap-0 text-right">
              <span className="font-bold text-sm">Current Epoch</span>
              <span className="opacity-50 text-xs">25% Completed</span>
            </div>
            <Badge
              variant="outline"
              className="border-green-600 text-green-600 text-md"
            >
              235
            </Badge>
          </h3>
        </div>
      </div>
      <div className="md:border md:p-4 rounded-2xl mb-4 w-full">
        <div className="flex flex-col justify-center">
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-6 gap-4">
            <div className="flex flex-col items-center bg-card p-4 rounded-xl">
              <DesoAvatar
                size={40}
                publicKey="BC1YLgi66tdjAaVfYpmM447cxsve3TpvfXD9h8X6JMak7gbKABoEVaT"
                username="mossified"
                clickable={true}
                className="mb-1 cursor-pointer border w-10 h-10 hover:border-secondary"
              />
              <div className="w-full h-8 flex justify-center items-center">
                <SocialCard
                  publicKey="BC1YLgi66tdjAaVfYpmM447cxsve3TpvfXD9h8X6JMak7gbKABoEVaT"
                  size="sm"
                />
              </div>
              <p className="text-xs text-center opacity-70">Current Leader</p>
            </div>
            {iterateLeaderSchedule.map((_, index) => (
              <div
                className="flex flex-col items-center border border-border-light p-4 rounded-xl"
                key={index}
              >
                <DesoAvatar
                  size={40}
                  publicKey="BC1YLgi66tdjAaVfYpmM447cxsve3TpvfXD9h8X6JMak7gbKABoEVaT"
                  username="mossified"
                  clickable={true}
                  className="mb-1 cursor-pointer border w-10 h-10 hover:border-secondary"
                />
                <div className="w-full h-8 flex justify-center items-center">
                  <SocialCard
                    publicKey="BC1YLgi66tdjAaVfYpmM447cxsve3TpvfXD9h8X6JMak7gbKABoEVaT"
                    size="sm"
                  />
                </div>
                <p className="text-xs text-center opacity-70">Upcoming</p>
                {/* Should be Current Leader > Upcoming > In Line */}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center pt-6">
        <h3 className="mb-4 flex items-center">
          <FlagIcon className="mr-2" />
          Validators
        </h3>
      </div>
      <div>
        <TableValidators
          transactions={[]}
          total={null}
          offset={0}
          perPage={0}
          loadingPage={false}
          onPrevPage={function (): void {
            throw new Error("Function not implemented.");
          }}
          onNextPage={function (): void {
            throw new Error("Function not implemented.");
          }}
        />
      </div>
    </main>
  );
};

export default ValidatorPage;
