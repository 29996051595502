import range from "lodash/range";
import { Skeleton } from "@/components/ui/skeleton";
import { Metric } from "@/components/ui/metric";
import {
  desoNanosToUSD,
  formatDecimalValue,
  formatUSD,
} from "../../utils/currency";
import { useContext } from "react";
import { MarketDataContext } from "../../contexts/market-data";
import { DashboardStat } from "../../graphql/codegen/graphql";

interface TransactionMetricsProps {
  dashboardStats: DashboardStat | null;
  loading: boolean;
  pendingTxns?: number;
}

const TransactionMetrics = ({
  dashboardStats,
  loading,
  pendingTxns,
}: TransactionMetricsProps) => {
  const { marketData, loading: loadingMarketData } =
    useContext(MarketDataContext);

  return (
    <header className="grid grid-cols-2 md:grid-cols-4 gap-4">
      {loading || loadingMarketData || !dashboardStats || !marketData ? (
        range(4).map((e) => <Skeleton className="h-[80px]" key={e} />)
      ) : (
        <>
          <Metric
            value={formatDecimalValue(dashboardStats.blockHeightCurrent)}
            label="Current Block Height"
          />
          <Metric
            value={formatDecimalValue(dashboardStats.txnCountAll)}
            label="Total Transactions"
          />
          <Metric
            value={
              pendingTxns || formatDecimalValue(dashboardStats.txnCountPending)
            }
            label="Pending Transactions"
          />
          <Metric
            value={formatUSD(
              desoNanosToUSD(
                dashboardStats.txnFee1D,
                marketData.current_price.usd * 100,
              ),
              true,
              7,
            )}
            caption="Per Post"
            label="Current Txn Fee"
          />
        </>
      )}
    </header>
  );
};

export default TransactionMetrics;
