import * as React from "react";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { InfoIcon } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useMobile } from "../../hooks/use-mobile";

interface InfoTooltipProps {
  triggerBtn?: JSX.Element | string;
  text: string;
  iconSize?: number;
}

export const InfoTooltip = ({
  triggerBtn,
  text,
  iconSize = 20,
}: InfoTooltipProps) => {
  const { isMobile } = useMobile();

  const iconStyle = triggerBtn
    ? {}
    : {
        width: `${iconSize}px`,
        height: `${iconSize}px`,
      };

  const triggerButton = triggerBtn ?? (
    <Button size="tooltip" className="bg-transparent h-auto w-auto">
      <InfoIcon
        className="text-foreground dark:text-secondary hover:text-muted-foreground"
        style={iconStyle}
      />
    </Button>
  );

  return isMobile ? (
    <Popover>
      <PopoverTrigger style={iconStyle}>{triggerButton}</PopoverTrigger>
      <PopoverContent>{text}</PopoverContent>
    </Popover>
  ) : (
    <TooltipProvider>
      <Tooltip delayDuration={50}>
        <TooltipTrigger asChild style={iconStyle}>
          <div>{triggerButton}</div>
        </TooltipTrigger>

        <TooltipContent>{text}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
