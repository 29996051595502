import { AtomIcon } from "lucide-react";
import { Metric } from "../ui/metric";
import { ProfileTransactionStatByPublicKeyDocument } from "../../graphql/codegen/graphql";
import { useQuery } from "@apollo/client";
import { client } from "../../graphql/client";
import dayjs from "dayjs";
import {
  desoNanosToDeso,
  desoNanosToUSD,
  formatDecimalValue,
  formatUSD,
} from "../../utils/currency";
import { useContext } from "react";
import { MarketDataContext } from "../../contexts/market-data";
import { Skeleton } from "@/components/ui/skeleton";

interface NetworkActivityProps {
  publicKey: string;
}

const SKELETON_NUM_OF_CARDS = 4;

const UserNetworkActivity = ({ publicKey }: NetworkActivityProps) => {
  const { exchangeRate } = useContext(MarketDataContext);

  const { loading: loadingNetworkActivity, data: userNetworkActivity } =
    useQuery(ProfileTransactionStatByPublicKeyDocument, {
      client,
      variables: {
        publicKey,
      },
    });

  const { firstTransactionTimestamp, latestTransactionTimestamp } =
    userNetworkActivity?.profileTransactionStatByPublicKey || {};
  const firstTransactionDate = dayjs(firstTransactionTimestamp);
  const latestTransactionDate = dayjs(latestTransactionTimestamp);

  const skeletonItemsIterator = Array.from(Array(SKELETON_NUM_OF_CARDS).keys());

  return (
    <div className="mb-12">
      <div className="flex justify-between items-center">
        <h3 className="mb-4 flex items-center">
          <AtomIcon className="mr-2" />
          Network Activity
        </h3>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
        {loadingNetworkActivity ? (
          skeletonItemsIterator.map((i) => (
            <Skeleton className="w-full h-[80px]" key={i} />
          ))
        ) : (
          <>
            <Metric
              value={
                firstTransactionTimestamp
                  ? firstTransactionDate.format("MMM DD, YYYY")
                  : "No data"
              }
              caption={
                firstTransactionTimestamp
                  ? firstTransactionDate.fromNow()
                  : undefined
              }
              label="Joined"
            />

            <Metric
              value={formatDecimalValue(
                userNetworkActivity?.profileTransactionStatByPublicKey?.count ||
                  0,
              )}
              label="Total Transactions"
              tooltip="Total number of all on-chain transactions"
            />

            <Metric
              value={
                userNetworkActivity?.profileTransactionStatByPublicKey
                  ? formatUSD(
                      desoNanosToUSD(
                        userNetworkActivity?.profileTransactionStatByPublicKey
                          ?.totalFees,
                        exchangeRate,
                      ),
                    )
                  : 0
              }
              caption={
                userNetworkActivity?.profileTransactionStatByPublicKey
                  ? `${formatDecimalValue(
                      desoNanosToDeso(
                        userNetworkActivity?.profileTransactionStatByPublicKey
                          ?.totalFees,
                      ),
                      6,
                    )} DESO`
                  : undefined
              }
              label="Total Txn Fees Spent"
            />

            <Metric
              value={
                latestTransactionTimestamp
                  ? latestTransactionDate.fromNow()
                  : "No activity"
              }
              label="Recent Activity"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default UserNetworkActivity;
