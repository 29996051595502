import { Button } from "../ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { Transaction } from "../../graphql/codegen/graphql";
import {
  formatTxnFees,
  formatTxnType,
  shortenLongWord,
} from "../../utils/helpers";
import { TableTimestampFormatter } from "@/components/shared/table-timestamp-formatter";
import { TxnActionItem } from "@/components/shared/txn-action-item";
import dayjs from "dayjs";
import { formatDecimalValue } from "../../utils/currency";
import { useContext } from "react";
import { MarketDataContext } from "../../contexts/market-data";
import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { MoreHorizontal } from "lucide-react";
import CopyToClipboard from "@/components/shared/copy-to-clipboard";
import BlockLink from "@/components/blocks/block-link";
import { useTableScroll } from "../../hooks/use-table-scroll";

interface TableTransactionsProps {
  transactions: Array<Transaction>;
  total: number | null;
  offset: number;
  perPage: number;
  loadingPage: boolean;
  hideHeightColumn?: boolean;
  onPrevPage: () => void;
  onNextPage: () => void;
  hasPrevPage?: boolean;
  hasNextPage?: boolean;
}

const TableTransactions = ({
  transactions,
  total,
  offset,
  perPage,
  loadingPage,
  hideHeightColumn,
  onPrevPage,
  onNextPage,
  hasPrevPage,
  hasNextPage,
}: TableTransactionsProps) => {
  const { wrapperElement, onPageChange } = useTableScroll();

  const { exchangeRate } = useContext(MarketDataContext);

  const headers = ["Txn Hash", "Age", "Action", "Txn Type", "Txn Fees", ""];

  if (!hideHeightColumn) {
    headers.unshift("Block Height");
  }

  return (
    <div className="w-full" ref={wrapperElement}>
      <div className="relative rounded-2xl border overflow-hidden">
        {loadingPage && (
          <div className="loader-line h-[4px] md:h-[2px] before:h-[4px] md:before:h-[2px]"></div>
        )}
        <Table>
          <TableHeader>
            <TableRow>
              {headers.map((e) => (
                <TableHead key={e}>{e}</TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {transactions.length === 0 ? (
              <TableRow>
                <TableCell colSpan={headers.length} className="text-center">
                  No transactions found.
                </TableCell>
              </TableRow>
            ) : (
              transactions.map((e) => {
                const timestamp = e?.block?.timestamp
                  ? dayjs(`${e.block.timestamp}.000Z`).valueOf()
                  : null;

                return (
                  <TableRow
                    key={e.transactionHash}
                    className="hover:bg-card dark:hover:bg-black/10 border-border-light"
                  >
                    {!hideHeightColumn && (
                      <TableCell className="align-middle p-1 px-4 text-xs">
                        <BlockLink
                          height={e.block?.height}
                          className="hover:underline"
                        />
                      </TableCell>
                    )}
                    <TableCell className="align-middle text-xs py-0 px-4">
                      <CopyToClipboard
                        text={e.transactionHash || ""}
                        iconSize={14}
                      >
                        <Link
                          to={`/txn/${e.transactionHash}`}
                          className="p-1 hover:underline"
                        >
                          {shortenLongWord(e.transactionHash, 5, 5)}
                        </Link>
                      </CopyToClipboard>
                    </TableCell>
                    <TableCell className="align-middle p-1 px-4 text-xs whitespace-nowrap">
                      <TableTimestampFormatter timestamp={timestamp} />
                    </TableCell>
                    <TableCell className="align-middle p-1 px-4 text-xs">
                      <TxnActionItem item={e} />
                    </TableCell>
                    <TableCell className="align-middle p-1 px-4 text-xs">
                      <div className="text-[11px] inline-block self-center border border-border-light border-solid rounded-full py-1 px-4 font-mono text-center">
                        {formatTxnType(e.txnType)}
                      </div>
                    </TableCell>
                    <TableCell className="align-middle p-1 px-4 text-xs">
                      {formatTxnFees(e.feeNanos, exchangeRate)}
                    </TableCell>
                    <TableCell className="align-top p-1 px-4 text-xs">
                      <div className="text-right flex flex-col items-end justify-between relative">
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              variant="ghost"
                              className="h-8 w-8 p-0 rounded-full"
                            >
                              <span className="sr-only">Open menu</span>
                              <MoreHorizontal className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>

                          <DropdownMenuContent align="end">
                            <DropdownMenuItem className="p-0">
                              <Link
                                to={`/txn/${e.transactionHash}`}
                                className="px-2 py-1.5 w-full"
                              >
                                View Details
                              </Link>
                            </DropdownMenuItem>

                            <DropdownMenuItem className="p-0">
                              <CopyToClipboard
                                text={e.transactionHash || ""}
                                showToast={true}
                                showIcon={false}
                                className="px-2 py-1.5 w-full"
                                toastText={
                                  "The transaction hash has been successfully copied to your clipboard"
                                }
                              >
                                Copy Txn Hash
                              </CopyToClipboard>
                            </DropdownMenuItem>

                            <DropdownMenuItem className="p-0">
                              <CopyToClipboard
                                text={e.publicKey || ""}
                                showToast={true}
                                showIcon={false}
                                className="px-2 py-1.5 w-full"
                                toastText={
                                  "The user public key has been successfully copied to your clipboard"
                                }
                              >
                                Copy User Pubkey
                              </CopyToClipboard>
                            </DropdownMenuItem>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>

      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-xs text-muted">
          Showing {offset + 1}-{offset + transactions.length}
          {total !== null ? (
            <span> of {formatDecimalValue(total)} transactions</span>
          ) : (
            " transactions"
          )}
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              onPrevPage();
              onPageChange();
            }}
            disabled={hasPrevPage !== undefined ? !hasPrevPage : offset === 0}
            className={loadingPage ? "pointer-events-none cursor-default" : ""}
          >
            Previous
          </Button>

          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              onNextPage();
              onPageChange();
            }}
            disabled={
              hasNextPage !== undefined
                ? !hasNextPage
                : offset + perPage >= (total || 0)
            }
            className={loadingPage ? "pointer-events-none cursor-default" : ""}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TableTransactions;
