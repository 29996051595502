import { Metric } from "@/components/ui/metric";
import { Skeleton } from "@/components/ui/skeleton";
import { useContext } from "react";
import {
  calcPercentage,
  desoNanosToUSD,
  formatDecimalValue,
  formatUSD,
} from "../../utils/currency";
import { MarketDataContext } from "../../contexts/market-data";
import { DashboardStat } from "../../graphql/codegen/graphql";

const SKELETON_NUM_OF_CARDS = 8;

interface DesoBlockhainMetricsProps {
  stats: DashboardStat;
  loadingStats: boolean;
}

const DesoBlockhainMetrics = ({
  stats,
  loadingStats,
}: DesoBlockhainMetricsProps) => {
  const { marketData, loading: loadingMarketData } =
    useContext(MarketDataContext);

  const skeletonItemsIterator = Array.from(Array(SKELETON_NUM_OF_CARDS).keys());

  return (
    <div className="mt-4 m-auto">
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {loadingStats || loadingMarketData || !stats || !marketData ? (
          <>
            {skeletonItemsIterator.map((i) => (
              <Skeleton className="w-full h-[80px]" key={i} />
            ))}
          </>
        ) : (
          <>
            {/* Top Row */}
            <Metric
              value={formatDecimalValue(stats.blockHeightCurrent)}
              label="Current Block Height"
            />
            <Metric
              value={formatDecimalValue(stats.walletCountAll)}
              label="Total Users (Wallets)"
            />
            <Metric
              value={formatDecimalValue(stats.txnCountAll)}
              label="Total Transactions (Txn)"
            />
            <Metric
              value={formatUSD(
                desoNanosToUSD(
                  stats.txnFee1D,
                  marketData.current_price.usd * 100,
                ),
                true,
                7,
              )}
              caption="Per Post"
              label="Current Txn Fee"
            />
            {/*Bottom Row*/}
            <Metric
              value={formatUSD(marketData.current_price.usd)}
              label="DESO Price"
              caption="USD"
            />
            <Metric
              value={formatUSD(marketData.market_cap.usd, false)}
              label="Marketcap"
              caption="USD"
            />
            <Metric
              value={formatDecimalValue(marketData.total_supply, 0)}
              label="Total Supply"
              caption="DESO"
              tooltip={`The circulating supply of DESO is ${formatDecimalValue(
                marketData.circulating_supply,
                0,
              )} (${calcPercentage(
                marketData.circulating_supply,
                marketData.total_supply,
              )}%)`}
            />
            <Metric
              value={formatUSD(marketData.total_volume.usd, false)}
              label="DeSo Volume"
              caption="24 hrs"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default DesoBlockhainMetrics;
