import { Button } from "../ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import {
  calcPercentage,
  desoNanosToDeso,
  desoNanosToUSD,
  formatDecimalValue,
  formatUSD,
} from "../../utils/currency";
import { TopHoldersFragment } from "../../graphql/codegen/graphql";
import { SocialCard } from "@/components/shared/social-card";
import { useContext } from "react";
import { MarketDataContext } from "../../contexts/market-data";
import Spinner from "@/components/shared/spinner";
import { useTableScroll } from "../../hooks/use-table-scroll";
import CopyToClipboard from "@/components/shared/copy-to-clipboard";

interface TableCoinHoldersProps {
  holders: Array<TopHoldersFragment>;
  total: number;
  offset: number;
  perPage: number;
  loadingPage: boolean;
  onPrevPage: () => void;
  onNextPage: () => void;
}

const TableCoinHolders = ({
  holders,
  total,
  offset,
  perPage,
  loadingPage,
  onPrevPage,
  onNextPage,
}: TableCoinHoldersProps) => {
  const { wrapperElement, onPageChange } = useTableScroll();

  const headers = ["Rank", "User", "Public Key", "$DESO", "$USD", "Ownership"];
  const { marketData, loading: loadingMarketData } =
    useContext(MarketDataContext);

  return (
    <div className="w-full" ref={wrapperElement}>
      <div className="rounded-2xl border relative overflow-hidden">
        {loadingPage && (
          <div className="loader-line h-[4px] md:h-[2px] before:h-[4px] md:before:h-[2px]"></div>
        )}

        <Table>
          <TableHeader>
            <TableRow>
              {headers.map((e) => (
                <TableHead key={e}>{e}</TableHead>
              ))}
            </TableRow>
          </TableHeader>

          <TableBody>
            {holders.map((e, i) => {
              return (
                <TableRow
                  key={e.publicKey}
                  className="hover:bg-card dark:hover:bg-black/10 border-border-light"
                >
                  <TableCell className="pt-2 pb-1 px-4 text-xs">
                    #{offset + i + 1}
                  </TableCell>
                  <TableCell className="pt-2 pb-1 px-4 text-xs h-[45px]">
                    <div className="flex items-center self-start">
                      <span className="ml-2">
                        <SocialCard publicKey={e.publicKey} showAvatar={true} />
                      </span>
                    </div>
                  </TableCell>
                  <TableCell className="pt-2 pb-1 px-4 text-xs">
                    <CopyToClipboard text={e.publicKey} iconSize={14}>
                      {e.publicKey}
                    </CopyToClipboard>
                  </TableCell>
                  <TableCell className="p-1 px-4 text-xs">
                    {formatDecimalValue(desoNanosToDeso(e.balanceNanos))}
                  </TableCell>
                  <TableCell className="p-1 px-4 text-xs">
                    {loadingMarketData || !marketData ? (
                      <Spinner />
                    ) : (
                      <>
                        {formatUSD(
                          desoNanosToUSD(
                            e.balanceNanos,
                            marketData.current_price.usd * 100,
                          ),
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell className="p-1 px-4 text-xs">
                    {formatDecimalValue(
                      calcPercentage(
                        desoNanosToDeso(e.balanceNanos),
                        marketData?.total_supply || 0,
                        false,
                      ),
                    )}
                    %
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>

      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-xs text-muted">
          Showing {offset + 1}-{offset + holders.length} of{" "}
          {formatDecimalValue(total)} holders
        </div>

        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              onPrevPage();
              onPageChange();
            }}
            disabled={offset === 0}
            className={loadingPage ? "pointer-events-none cursor-default" : ""}
          >
            Previous
          </Button>

          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              onNextPage();
              onPageChange();
            }}
            disabled={offset + perPage >= total}
            className={loadingPage ? "pointer-events-none cursor-default" : ""}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TableCoinHolders;
