import { Link } from "react-router-dom";
import { DIAMOND_APP_URL } from "../../utils/constants";

export const DiamondPostLink = ({
  postId,
  text = "post",
}: {
  postId: string;
  text?: string;
}) => {
  return (
    <Link
      to={`${DIAMOND_APP_URL}/posts/${postId}`}
      className="underline"
      target="_blank"
    >
      {text}
    </Link>
  );
};
