import { BookKeyIcon, GemIcon } from "lucide-react";
import { Metric } from "../ui/metric";
import PlotEarnings from "@/components/userDetail/plot-earnings";
import {
  ProfileEarningsByPublicKeyDocument,
  ProfilePortfolioByPublicKeyDocument,
} from "../../graphql/codegen/graphql";
import { useQuery } from "@apollo/client";
import { client } from "../../graphql/client";
import sum from "lodash/sum";
import {
  desoNanosToDeso,
  desoToUSD,
  formatDecimalValue,
  formatUSD,
} from "../../utils/currency";
import { useContext } from "react";
import { MarketDataContext } from "../../contexts/market-data";
import { Skeleton } from "@/components/ui/skeleton";

interface AccumulatedEarningsProps {
  publicKey: string;
}

const AccumulatedEarnings = ({ publicKey }: AccumulatedEarningsProps) => {
  const { marketData, loading: loadingMarketData } =
    useContext(MarketDataContext);

  const { loading: loadingEarnings, data: earnings } = useQuery(
    ProfileEarningsByPublicKeyDocument,
    {
      client,
      variables: {
        publicKey,
      },
    },
  );

  const { loading: loadingPortfolio, data: portfolio } = useQuery(
    ProfilePortfolioByPublicKeyDocument,
    {
      client,
      variables: {
        publicKey,
      },
    },
  );

  const {
    totalCcRoyaltyNanos = 0,
    totalNftRoyaltyNanos = 0,
    totalDiamondNanos = 0,
  } = earnings?.profileEarningsStatByPublicKey || {};

  const totalFounderReward = desoNanosToDeso(totalCcRoyaltyNanos);
  const totalNFTReward = desoNanosToDeso(totalNftRoyaltyNanos);
  const totalDiamondReward = desoNanosToDeso(totalDiamondNanos);

  const totalEarnings = sum([
    totalFounderReward,
    totalNFTReward,
    totalDiamondReward,
  ]);

  const earningsChartData = marketData
    ? [
        {
          name: "CC (Founders Reward)",
          data: [
            Math.round(
              desoToUSD(
                totalFounderReward,
                marketData?.current_price.usd * 100,
              ),
            ),
          ],
        },
        {
          name: "NFT Royalties",
          data: [
            Math.round(
              desoToUSD(totalNFTReward, marketData?.current_price.usd * 100),
            ),
          ],
        },
        {
          name: "Diamonds",
          data: [
            Math.round(
              desoToUSD(
                totalDiamondReward,
                marketData?.current_price.usd * 100,
              ),
            ),
          ],
        },
      ]
    : [];

  const {
    desoBalanceValueNanos = 0,
    ccValueNanos = 0,
    nftValueNanos = 0,
    tokenValueNanos = 0,
  } = portfolio?.profilePortfolioValueStatByPublicKey || {};

  const totalDesoBalance = desoNanosToDeso(desoBalanceValueNanos);
  const totalCCBalance = desoNanosToDeso(ccValueNanos);
  const totalNFTBalance = desoNanosToDeso(nftValueNanos);
  const totalTokenBalance = desoNanosToDeso(tokenValueNanos);

  const totalPortfolio = sum([
    totalDesoBalance,
    totalCCBalance,
    totalNFTBalance,
    totalTokenBalance,
  ]);

  const portfolioChart = marketData
    ? [
        {
          name: "$DESO",
          data: [
            Math.round(
              desoToUSD(totalDesoBalance, marketData?.current_price.usd * 100),
            ),
          ],
        },
        {
          name: "Creator Coins",
          data: [
            Math.round(
              desoToUSD(totalCCBalance, marketData?.current_price.usd * 100),
            ),
          ],
        },
        {
          name: "NFTs",
          data: [
            Math.round(
              desoToUSD(totalNFTBalance, marketData?.current_price.usd * 100),
            ),
          ],
        },
        {
          name: "Tokens",
          data: [
            Math.round(
              desoToUSD(totalTokenBalance, marketData?.current_price.usd * 100),
            ),
          ],
        },
      ]
    : [];

  return (
    <div>
      <div className="flex items-center p-4">
        <h3 className="text-xs flex items-center">
          Total Earnings & Portfolio (All Time)
        </h3>
      </div>

      <div className="px-4 grid grid-cols-1 lg:grid-cols-2 gap-4 border-b border-b-border-light">
        {loadingEarnings || loadingMarketData || !marketData ? (
          <div className="flex flex-col">
            <Skeleton className="h-[90px] mb-4" />
            <Skeleton className="h-[300px]" />
          </div>
        ) : (
          <div className="flex flex-col">
            <Metric
              icon={<GemIcon />}
              value={formatUSD(
                desoToUSD(totalEarnings, marketData?.current_price.usd * 100),
                false,
              )}
              caption={`${formatDecimalValue(totalEarnings)} DESO`}
              label="Total Earnings"
              tooltip="CC Buys (Founders Reward) + NFT Sales & Royalties + Diamonds Received"
            />

            <PlotEarnings series={earningsChartData} colorFrom="#ffda59" />
          </div>
        )}

        {loadingPortfolio || loadingMarketData || !marketData ? (
          <div className="flex flex-col">
            <Skeleton className="h-[90px] mb-4" />
            <Skeleton className="h-[300px]" />
          </div>
        ) : (
          <div className="flex flex-col">
            <Metric
              icon={<BookKeyIcon />}
              value={formatUSD(
                desoToUSD(totalPortfolio, marketData?.current_price.usd * 100),
                false,
              )}
              caption={`${formatDecimalValue(totalPortfolio)} DESO`}
              label="Total Portfolio Value"
              tooltip="$DESO + Creator Coins + NFT (Last Sale) + Tokens"
            />

            <PlotEarnings series={portfolioChart} colorFrom="#93c5fd" />
          </div>
        )}
      </div>
    </div>
  );
};

export default AccumulatedEarnings;
