import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import { Blocks } from "./pages/blocks";
import { CoinHolders } from "./pages/coin-holders";
import { Home } from "./pages/home";
import { Transactions } from "./pages/transactions";
import TransactionDetail from "./pages/transaction-detail";
import BlockDetail from "./pages/block-detail";
import UserDetail from "./pages/user-detail";
import ValidatorPage from "./pages/validators";
import ValidatorDetailPage from "./pages/validator-detail";
import ValidatorSettingsPage from "./pages/validator-settings";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/validators",
        element: <ValidatorPage />,
      },
      {
        path: "/validator-detail",
        element: <ValidatorDetailPage />,
      },
      {
        path: "/validator-settings",
        element: <ValidatorSettingsPage />,
      },
      {
        path: "/stats/coin-holders",
        element: <CoinHolders />,
      },
      {
        path: "/txn",
        element: <Transactions />,
      },
      {
        path: "/blocks",
        element: <Blocks />,
      },
      {
        path: "/txn/:transactionId",
        element: <TransactionDetail />,
      },
      {
        path: "/blocks/:blockId",
        element: <BlockDetail />,
      },
      {
        path: "/mempool",
        element: <BlockDetail mempool={true} />,
      },
      {
        path: "/u/:userId",
        element: <UserDetail />,
      },
    ],
  },
]);
