import { Link, useLocation } from "react-router-dom";
import { UserDropdown } from "@/components/shared/user-dropdown";
import { Button } from "@/components/ui/button";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { Menu, WalletIcon } from "lucide-react";
import { ThemeToggle } from "@/components/shared/theme-toggle";
import { BuyDesoModal } from "@/components/shared/buy-deso-modal";
import ListItem from "../ui/list-item";
import { useContext, useEffect, useState } from "react";
import { ActiveAccountContext } from "../../contexts/active-account";
import Spinner from "@/components/shared/spinner";
import { identity } from "deso-protocol";
import GlobalSearch from "@/components/shared/global-search";
import SubHeader from "@/components/shared/sub-header";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTrigger,
} from "../ui/sheet";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import { ThemeContext } from "../../contexts/theme";
import { DESO_REVOLUTION_URL, THEME_OPTIONS } from "../../utils/constants";
import { Label } from "../ui/label";
import WalletLink from "@/components/shared/wallet-link";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

const dashboardMenu = [
  {
    title: "Home",
    href: "/",
    description:
      "Go to the main dashboard page of the DeSo blockchain explorer.",
  },
  {
    title: "Top $DESO Holders",
    href: "/stats/coin-holders",
    description: "See the top accounts that hold the DESO token supply.",
  },
];

const blockMenu = [
  {
    title: "Blocks",
    href: "/blocks",
    description:
      "View all mempool transactions & mined blocks on the DeSo Blockchain.",
  },
  {
    title: "Transactions",
    href: "/txn",
    description:
      "View all transactions that are happening on the DeSo Blockchain.",
  },
];

const developersMenu = [
  {
    title: "DeSo Official Github",
    href: "https://github.com/deso-protocol",
    external: true,
    description: "The DeSo blockchain is 100% open-source and permissionless.",
  },
  {
    title: "DeSo Developer Docs",
    href: "https://docs.deso.org",
    external: true,
    description:
      "Learn about the DeSo vision & read our developer documentation.",
  },
  {
    title: "DeSo JavaScript SDK (deso.js)",
    href: "https://docs.deso.org/deso-frontend/get-started",
    external: true,
    description:
      "Build fully crypto-native apps on the DeSo blockchain with JavaScript",
  },
  {
    title: "DeSo GraphQL API",
    href: "https://graphql-prod.deso.com/",
    external: true,
    description:
      "Interact with the DeSo GraphQL API explorer for frontend apps.",
  },
  {
    title: "DeSo Chat Protocol",
    href: "https://chat.deso.com/",
    external: true,
    description:
      "End-to-end encrypted messaging framework for DMs and group chats.",
  },
  {
    title: "Revolution Proof of Stake",
    href: "https://revolution.deso.com/",
    external: true,
    description:
      "Read the Revolution Proof of Stake whitepaper, coming soon to DeSo.",
  },
];

export function Header() {
  const { theme, setTheme } = useContext(ThemeContext);

  const location = useLocation();

  const { account, accountLoading } = useContext(ActiveAccountContext);
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    setOpenMenu(false);
  }, [location]);

  return (
    <div className="relative bg-background z-50 w-full border-b border-border-light">
      <div className="flex justify-between px-2 md:py-1">
        <div className="flex items-center w-full md:w-auto">
          <div className="flex w-full md:h-auto items-center">
            <div className="xl:hidden mr-2">
              <Sheet open={openMenu} onOpenChange={setOpenMenu}>
                <SheetTrigger>
                  <Menu
                    className="w-8 h-8 mt-1"
                    onClick={() => {
                      return;
                    }}
                  />
                </SheetTrigger>

                <SheetContent
                  className="w-[85%] overflow-auto"
                  onOpenAutoFocus={(e) => {
                    e.preventDefault();
                  }}
                >
                  <SheetHeader>
                    <SheetDescription className="text-start">
                      <Link className="flex items-center mb-6" to="/">
                        <div className="inline-block">
                          <img
                            alt="deso-logo"
                            src="/assets/img/logo-deso.svg"
                            width="100"
                            className="max-w-none pl-2 hidden dark:block"
                          />
                          <img
                            alt="deso-logo"
                            src="/assets/img/logo-deso-dark.svg"
                            width="100"
                            className="max-w-none pl-2 block dark:hidden"
                          />
                        </div>
                        <span className="inline-block ml-3 relative text-sm top-[1px]">
                          Explorer
                        </span>
                      </Link>

                      <div className="mb-8">
                        <GlobalSearch />
                      </div>

                      <div className="mb-4">
                        <h3 className="text-muted">Navigation</h3>
                      </div>

                      <Accordion type="single" collapsible className="w-full">
                        <AccordionItem value="item-1">
                          <AccordionTrigger className="hover:no-underline">
                            Dashboard
                          </AccordionTrigger>
                          <AccordionContent>
                            {dashboardMenu.map((item, index) => (
                              <Link
                                key={index}
                                title={item.title}
                                to={item.href}
                                className="block mt-2 bg-popover px-4 py-2 rounded-xl"
                              >
                                {item.title}
                              </Link>
                            ))}
                          </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="item-2">
                          <AccordionTrigger className="hover:no-underline">
                            Blocks & Transactions
                          </AccordionTrigger>
                          <AccordionContent>
                            {blockMenu.map((item, index) => (
                              <Link
                                key={index}
                                title={item.title}
                                to={item.href}
                                className="block mt-2 bg-popover px-4 py-2 rounded-xl"
                              >
                                {item.title}
                              </Link>
                            ))}
                          </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="item-3">
                          <AccordionTrigger className="hover:no-underline">
                            Developers
                          </AccordionTrigger>
                          <AccordionContent>
                            {developersMenu.map((item, index) => (
                              <Link
                                key={index}
                                title={item.title}
                                to={item.href}
                                className="block mt-2 bg-popover px-4 py-2 rounded-xl"
                              >
                                {item.title}
                              </Link>
                            ))}
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>

                      <div className="mb-8 mt-8">
                        <h3 className="text-muted mb-2">Choose Theme:</h3>

                        <RadioGroup
                          defaultValue={theme}
                          onValueChange={(v) => setTheme(v as THEME_OPTIONS)}
                        >
                          <div className="flex items-center space-x-2 border border-border rounded-md">
                            <RadioGroupItem
                              value={THEME_OPTIONS.lightMode}
                              id={`theme-${THEME_OPTIONS.lightMode}`}
                              className="ml-4"
                            />

                            <Label
                              htmlFor={`theme-${THEME_OPTIONS.lightMode}`}
                              className="p-4 pl-0 w-full"
                            >
                              Light
                            </Label>
                          </div>

                          <div className="flex items-center space-x-2 border border-border rounded-md">
                            <RadioGroupItem
                              value={THEME_OPTIONS.darkMode}
                              id={`theme-${THEME_OPTIONS.darkMode}`}
                              className="ml-4"
                            />

                            <Label
                              htmlFor={`theme-${THEME_OPTIONS.darkMode}`}
                              className="p-4 pl-0 w-full"
                            >
                              Dark
                            </Label>
                          </div>
                        </RadioGroup>
                      </div>
                      <div className="mb-8">
                        <BuyDesoModal />
                      </div>
                    </SheetDescription>
                  </SheetHeader>
                  <div className="mb-5">
                    <SubHeader className="p-0" compact={true} />
                  </div>
                </SheetContent>
              </Sheet>
            </div>

            <Link className="flex items-center mr-6" to="/">
              <div className="inline-block">
                <img
                  alt="deso-logo"
                  src="/assets/img/logo-deso.svg"
                  width="100"
                  className="max-w-none pl-2 hidden dark:block"
                />

                <img
                  alt="deso-logo"
                  src="/assets/img/logo-deso-dark.svg"
                  width="100"
                  className="max-w-none pl-2 block dark:hidden"
                />
              </div>

              <span className="inline-block ml-3 relative text-sm top-[1px]">
                Explorer
              </span>
            </Link>
          </div>

          <section className="xl:block hidden">
            <Navigation />
          </section>
        </div>

        <div className="flex items-center py-2">
          <div className="md:flex hidden">
            <div className="w-auto md:w-[400px] mr-2">
              <GlobalSearch />
            </div>

            <ThemeToggle />

            <WalletLink>
              <Button
                variant="outline"
                size="icon"
                className="ml-2 rounded-full border-border"
              >
                <WalletIcon className="h-[20px] w-[20px]" />
              </Button>
            </WalletLink>

            <BuyDesoModal />
          </div>

          <section className="ml-2 flex items-center justify-center min-w-[40px] h-[40px]">
            {accountLoading ? (
              <Spinner size={32} />
            ) : account ? (
              <UserDropdown />
            ) : (
              <Button variant="outline" onClick={() => identity.login()}>
                Login
              </Button>
            )}
          </section>
        </div>
      </div>
    </div>
  );
}

const Navigation = () => {
  return (
    <NavigationMenu className="z-50 relative">
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger>
            <Link to="/">Dashboard</Link>
          </NavigationMenuTrigger>

          <NavigationMenuContent>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
              {dashboardMenu.map((item, index) => (
                <ListItem key={index} title={item.title} href={item.href}>
                  {item.description}
                </ListItem>
              ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>

        <NavigationMenuItem>
          <NavigationMenuTrigger>
            <Link to="/blocks">Blocks</Link>
          </NavigationMenuTrigger>

          <NavigationMenuContent>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
              {blockMenu.map((item, index) => (
                <ListItem key={index} title={item.title} href={item.href}>
                  {item.description}
                </ListItem>
              ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>

        <NavigationMenuItem>
          <NavigationMenuTrigger>
            <Link to="https://docs.deso.org">Developers</Link>
          </NavigationMenuTrigger>

          <NavigationMenuContent>
            <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] ">
              {developersMenu.map((item, index) => (
                <ListItem
                  key={index}
                  title={item.title}
                  href={item.href}
                  target="_blank"
                  external={true}
                >
                  {item.description}
                </ListItem>
              ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>

        <NavigationMenuItem>
          <NavigationMenuLink
            className={navigationMenuTriggerStyle()}
            href="/validators"
          >
            Validators
          </NavigationMenuLink>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
};
