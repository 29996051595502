import TableValidators from "@/components/shared/table-validators";
import { Metric } from "@/components/ui/metric";
import {
  AtomIcon,
  FlagIcon,
  LucideBadgeDollarSign,
  User2Icon,
} from "lucide-react";
import ValidatorCard from "@/components/userDetail/validator-card";
import TableStakers from "@/components/shared/table-stakers";

const ValidatorDetailPage = () => {
  return (
    <main className="mt-4 container m-auto">
      <div className="w-full justify-start flex items-center">
        <h1 className="text-2xl text-black dark:text-white font-semibold">
          Validator Detail
        </h1>
      </div>
      <div className="mt-8">
        <ValidatorCard />
        <div className="flex justify-between items-center">
          <h3 className="mb-4 flex items-center">
            <FlagIcon className="mr-2" />
            Validator Overview
          </h3>
        </div>
        <div className="grid align-center grid-cols-1 md:grid-cols-2 xl:grid-cols-5 w-full gap-4">
          <div className="flex flex-col gap-2">
            <Metric value="16,721" caption="DESO" label="Total Stake" />
          </div>
          <div className="flex flex-col gap-2">
            <Metric value="4.56%" label="Stake Percentage" />
          </div>
          <div className="flex flex-col gap-2">
            <Metric value="4.14%" label="Commission Fee %" />
          </div>
          <div className="flex flex-col gap-2">
            <Metric value="19.58%" label="Average APY%" />
          </div>
          <div className="flex flex-col gap-2">
            <Metric value="149" label="Unique Stakers" />
          </div>
        </div>
      </div>
      <div className="mt-8 pb-8 border-b border-border-light">
        <div className="flex justify-between items-center">
          <h3 className="mb-4 flex items-center">
            <User2Icon className="mr-2" />
            My Stake Entries
          </h3>
        </div>
        <div className="grid align-center grid-cols-1 md:grid-cols-3 w-full gap-4">
          <div className="flex flex-col gap-2">
            <Metric value="10.05" caption="DESO" label="My Staked Balance" />
          </div>
          <div className="flex flex-col gap-2">
            <Metric value="0.245" label="My Claimed Rewards" caption="DESO" />
          </div>
          <div className="flex flex-col gap-2">
            <Metric
              value="12:30:24"
              caption="HH:MM:SS"
              label="My Next Reward"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center pt-6">
        <h3 className="mb-4 flex items-center">
          <LucideBadgeDollarSign className="mr-2" />
          Stakers
        </h3>
      </div>
      <div>
        <TableStakers
          transactions={[]}
          total={null}
          offset={0}
          perPage={0}
          loadingPage={false}
          onPrevPage={function (): void {
            throw new Error("Function not implemented.");
          }}
          onNextPage={function (): void {
            throw new Error("Function not implemented.");
          }}
        />
      </div>
    </main>
  );
};

export default ValidatorDetailPage;
