import { BoxesIcon } from "lucide-react";
import TableTransactions from "../shared/table-transactions";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  Transaction,
  TransactionsDocument,
  TransactionsOrderBy,
} from "../../graphql/codegen/graphql";
import { client } from "../../graphql/client";
import { useToast } from "@/components/ui/use-toast";
import { Skeleton } from "../ui/skeleton";
import { Link } from "react-router-dom";

const ITEMS_PER_PAGE = 10;

interface RecentTransactionsProps {
  totalTxn?: number;
  publicKey?: string;
  viewAllLink?: string;
}

const RecentTransactions = ({
  totalTxn,
  publicKey,
  viewAllLink = "/txn",
}: RecentTransactionsProps) => {
  const { toast } = useToast();

  const [initLoading, setInitLoading] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [transactions, setTransactions] = useState<Array<Transaction>>([]);
  const [hasPrevPage, setHasPrevPage] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);

  const [fetchTransactionsLazy, { loading }] = useLazyQuery(
    TransactionsDocument,
    {
      client,
    },
  );

  const requestFilters = publicKey
    ? {
        blockHeight: {
          isNull: false,
        },
        publicKey: {
          equalTo: publicKey,
        },
      }
    : {
        blockHeight: {
          isNull: false,
        },
      };

  const fetchTransactions = async () => {
    const { data } = await fetchTransactionsLazy({
      variables: {
        first: ITEMS_PER_PAGE,
        orderBy: [
          TransactionsOrderBy.TimestampDesc,
          TransactionsOrderBy.IndexInBlockAsc,
        ],
        offset,
        filter: requestFilters,
        withTotal: false,
      },
    });

    setHasPrevPage(data?.transactions?.pageInfo.hasPreviousPage || false);
    setHasNextPage(data?.transactions?.pageInfo.hasNextPage || false);

    return data?.transactions?.nodes.filter(
      (e) => e !== null,
    ) as Array<Transaction>;
  };

  useEffect(() => {
    setOffset(0);
  }, [publicKey]);

  useEffect(() => {
    const getTransactions = async () => {
      try {
        const transactionsList = await fetchTransactions();
        setTransactions(transactionsList || []);
      } catch (e: any) {
        toast({
          variant: "destructive",
          title: "Error",
          description: `There was a problem getting recent transactions. ${JSON.stringify(
            e,
          )}`,
        });
      } finally {
        setInitLoading(false);
      }
    };

    getTransactions();
  }, [offset]);

  return (
    <div className="my-12">
      <div className="flex justify-between items-center">
        <h3 className="mb-4 flex items-center" id="recent-transactions">
          <BoxesIcon className="mr-2" />
          Recent Transactions
        </h3>

        <Link to={viewAllLink} className="text-xs hover:underline">
          View All Transactions
        </Link>
      </div>

      {initLoading ? (
        <div className="overflow-hidden rounded-xl">
          <Skeleton className="h-[48px] w-full rounded-none mb-1" />
          <Skeleton className="h-[300px] w-full rounded-none" />
        </div>
      ) : (
        <TableTransactions
          transactions={transactions}
          total={totalTxn || null}
          perPage={ITEMS_PER_PAGE}
          offset={offset}
          onPrevPage={() => {
            setOffset((prev) => prev - ITEMS_PER_PAGE);
          }}
          onNextPage={() => {
            setOffset((prev) => prev + ITEMS_PER_PAGE);
          }}
          loadingPage={loading}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
        />
      )}
    </div>
  );
};

export default RecentTransactions;
