import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { Transaction, TransactionsDocument } from "../graphql/codegen/graphql";
import { client } from "../graphql/client";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import {
  ArrowLeftCircleIcon,
  BellIcon,
  BoxesIcon,
  ScrollTextIcon,
  User2Icon,
  Users2Icon,
} from "lucide-react";
import {
  BasicTransferOutput,
  TxnActionItem,
} from "@/components/shared/txn-action-item";
import { SocialCard } from "@/components/shared/social-card";
import { Metric } from "@/components/ui/metric";
import {
  formatTxnFees,
  formatTxnType,
  formatTxnUsername,
  getTransactionInfo,
  publicKeyByteArrayToBase58Check,
  range,
  shortenLongWord,
} from "../utils/helpers";
import { useContext, useState } from "react";
import { MarketDataContext } from "../contexts/market-data";
import dayjs from "dayjs";
import { TRANSACTION_TYPE, TRANSACTION_TYPES_BY_INDEX } from "../types";
import maxBy from "lodash/maxBy";
import { ThemeContext } from "../contexts/theme";
import { MEMPOOL_LABEL } from "../utils/constants";
import { Skeleton } from "@/components/ui/skeleton";
import CopyToClipboard from "@/components/shared/copy-to-clipboard";
import { formatDecimalValue } from "../utils/currency";
import { useMobile } from "../hooks/use-mobile";
import { useTitle } from "../hooks/use-title";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
import { CodeHighlighter } from "@/components/shared/code-highlighter";

const SKELETON_NUM_OF_CARDS = 3;

const getSenderPublicKeyIfAvailable = (transaction?: Transaction) => {
  if (!transaction) {
    return "";
  }

  switch (TRANSACTION_TYPES_BY_INDEX[transaction.txnType || -1]) {
    case TRANSACTION_TYPE.TxnTypeBlockReward:
      return "";
    default:
      return transaction?.publicKey || "";
  }
};

const getReceiverPublicKeyIfAvailable = (transaction?: Transaction) => {
  if (!transaction) {
    return "";
  }

  switch (TRANSACTION_TYPES_BY_INDEX[transaction.txnType || -1]) {
    case TRANSACTION_TYPE.TxnTypeBasicTransfer:
      const outputs = (transaction.outputs || []) as Array<BasicTransferOutput>;
      return maxBy(
        outputs.filter((o) => o.public_key !== transaction.publicKey),
        "amount_nanos",
      )?.public_key;
    case TRANSACTION_TYPE.TxnTypeNewMessage:
      return publicKeyByteArrayToBase58Check(
        transaction.txnMeta.RecipientAccessGroupOwnerPublicKey,
      );
    case TRANSACTION_TYPE.TxnTypePrivateMessage:
      return formatTxnUsername(transaction.txnMeta.RecipientPublicKey);
    case TRANSACTION_TYPE.TxnTypeDAOCoinTransfer:
    case TRANSACTION_TYPE.TxnTypeCreatorCoinTransfer:
    case TRANSACTION_TYPE.TxnTypeNFTTransfer:
      return formatTxnUsername(transaction.txnMeta.ReceiverPublicKey);
    case TRANSACTION_TYPE.TxnTypeBlockReward:
      return transaction.affectedPublicKeys.nodes[0]?.publicKey || "";
    case TRANSACTION_TYPE.TxnTypeFollow:
      return formatTxnUsername(transaction.txnMeta.FollowedPublicKey);
    case TRANSACTION_TYPE.TxnTypeCreateUserAssociation:
      return publicKeyByteArrayToBase58Check(
        transaction.txnMeta.TargetUserPublicKey,
      );
    default:
      return "";
  }
};

const prettifyJSON = (txnJSON: Transaction) => {
  const jsonCopy = cloneDeep(txnJSON);

  const possibleByteStringFields = [
    "txnMeta.ProfilePublicKey",
    "txnMeta.RecipientPublicKey",
    "txnMeta.FollowedPublicKey",
    "txnMeta.ReceiverPublicKey",
    "txnMeta.FromPublicKey",
    "txnMeta.ToPublicKey",
    "txnMeta.DerivedPublicKey",
    "txnMeta.MessagingPublicKey",
    "txnMeta.AccessGroupPublicKey",
    "txnMeta.AccessGroupOwnerPublicKey",
  ];

  possibleByteStringFields.forEach((fieldPath: string) => {
    if (get(jsonCopy, fieldPath)) {
      set(jsonCopy, fieldPath, formatTxnUsername(get(jsonCopy, fieldPath)));
    }
  });

  if (jsonCopy.txnMeta.AccessGroupMembersList) {
    set(
      jsonCopy,
      "txnMeta.AccessGroupMembersList",
      jsonCopy.txnMeta.AccessGroupMembersList.map((e: any) => ({
        ...e,
        AccessGroupMemberPublicKey: formatTxnUsername(
          e.AccessGroupMemberPublicKey,
        ),
      })),
    );
  }

  return JSON.stringify(jsonCopy, null, 4);
};

const convertSnakeCaseToTitleCaseWithExceptions = (txnName: string): string => {
  const exceptions = ["NFT", "DAO"];
  const words = txnName.split("_");

  const processedWords = words.map((word) => {
    if (exceptions.includes(word.toUpperCase())) {
      return word;
    }

    return capitalize(word.toLowerCase());
  });

  return processedWords.join(" ");
};

const TransactionDetail = () => {
  useTitle("Transaction Details");

  const { isMobile } = useMobile();
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const { transactionId } = useParams();
  const { exchangeRate } = useContext(MarketDataContext);

  const [showRawJSON, setShowRawJSON] = useState<boolean>(false);

  const { loading: loadingTransaction, data: transaction } = useQuery(
    TransactionsDocument,
    {
      client,
      variables: {
        filter: transactionId?.startsWith("3J")
          ? {
              transactionId: {
                equalTo: transactionId,
              },
            }
          : {
              transactionHash: {
                equalTo: transactionId,
              },
            },
        withTotal: false,
        first: 1,
        offset: 0,
      },
    },
  );

  const transactionDetails = transaction?.transactions?.nodes[0] as Transaction;
  const transactionDate = transactionDetails?.block?.timestamp
    ? dayjs(`${transactionDetails.block.timestamp}.000Z`)
    : null;
  const txnFee = formatTxnFees(transactionDetails?.feeNanos, exchangeRate);
  const txnType = formatTxnType(transactionDetails?.txnType);

  const senderPublicKey = getSenderPublicKeyIfAvailable(transactionDetails);
  const receiverPublicKey = getReceiverPublicKeyIfAvailable(transactionDetails);

  return (
    <main className="container m-auto pt-4">
      {!isMobile && (
        <Button
          variant="outline"
          size="sm"
          className="mb-8 rounded-full"
          onClick={() => navigate(-1)}
        >
          <ArrowLeftCircleIcon className="mr-2 w-5 h-5" />
          Go Back
        </Button>
      )}

      <div className="text-left m-auto mb-6">
        <h1 className="text-2xl text-black dark:text-white font-semibold">
          Transaction Detail
        </h1>
      </div>

      <section>
        <div className="mt-8 m-auto mb-12">
          <div className="mb-4">
            {loadingTransaction ? (
              <Skeleton className="h-[150px]" />
            ) : (
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-4">
                {senderPublicKey && (
                  <div>
                    <div className="mb-4">
                      <h3 className="flex gap-2 items-center">
                        <User2Icon className="h-[22px]" />
                        Sender
                      </h3>
                    </div>

                    <SocialCard publicKey={senderPublicKey} hoverable={false} />
                  </div>
                )}

                {receiverPublicKey && (
                  <div>
                    <div className="mb-4">
                      <h3 className="flex gap-2 items-center">
                        <Users2Icon className="h-[22px]" />
                        Receiver
                      </h3>
                    </div>

                    <SocialCard
                      publicKey={receiverPublicKey}
                      hoverable={false}
                    />
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            {loadingTransaction || !transactionDetails ? (
              range(SKELETON_NUM_OF_CARDS).map((e, i) => (
                <Skeleton className="h-[115px]" key={i} />
              ))
            ) : (
              <>
                <Metric
                  value={convertSnakeCaseToTitleCaseWithExceptions(txnType)}
                  label="Transaction Type"
                  tooltip={`Consensus name: ` + txnType}
                />
                <Metric
                  value={txnFee}
                  label="Transaction Fee"
                  tooltip="Denominated in $DESO"
                />

                <Metric
                  value={
                    transactionDate === null
                      ? MEMPOOL_LABEL
                      : transactionDate.format("MMM DD, YYYY")
                  }
                  caption={
                    transactionDate === null
                      ? undefined
                      : `@ ${transactionDate.format("H:mma")}`
                  }
                  label="Timestamp"
                  tooltip="Based on your local timezone preferences"
                />
              </>
            )}
          </div>
        </div>

        <div className="mb-12">
          <div className="flex items-start flex-col lg:flex-row lg:items-center lg:justify-between">
            <h3 className="flex gap-2 items-center">
              <BellIcon />
              Summary
            </h3>

            {loadingTransaction || !transactionDetails ? (
              <Skeleton className="h-[16px] w-full max-w-[500px]" />
            ) : (
              <p className="font-mono text-xs mt-6 lg:mt-0">
                <CopyToClipboard
                  text={transactionDetails.transactionHash || ""}
                >
                  <span className="text-muted-foreground mr-1">Txn Hash:</span>
                  {isMobile
                    ? shortenLongWord(transactionDetails.transactionHash, 8, 8)
                    : transactionDetails.transactionHash || ""}
                </CopyToClipboard>
              </p>
            )}
          </div>

          {loadingTransaction || !transactionDetails ? (
            <Skeleton className="h-[42px] w-full mt-4" />
          ) : (
            <div className="text-sm p-2 mt-4 px-4 border border-border rounded-xl flex items-center gap-1">
              <TxnActionItem item={transactionDetails} wrap={true} />
            </div>
          )}
        </div>

        <div className="mb-4">
          <h3 className="flex gap-2 items-center">
            <BoxesIcon />
            All Details
          </h3>
        </div>

        <div className="mb-12">
          {loadingTransaction || !transactionDetails ? (
            <Skeleton className="mt-4 w-full h-[300px]" />
          ) : (
            <div className="border border-border rounded-2xl font-mono">
              <div className="flex flex-col lg:flex-row gap-1 lg:gap-2 items-start lg:items-center border-b border-border-light p-3 text-sm">
                <h3 className="text-muted-foreground w-full max-w-[170px]">
                  Block Height:
                </h3>
                <p className="font-mono">
                  {parseInt(transactionDetails.block?.height)
                    ? formatDecimalValue(transactionDetails.block?.height)
                    : MEMPOOL_LABEL}
                </p>
              </div>
              {transactionDetails.transactionHash && (
                <div className="flex flex-col lg:flex-row gap-1 lg:gap-2 items-start lg:items-center border-b border-border-light p-3 text-sm">
                  <h3 className="text-muted-foreground w-full max-w-[170px]">
                    Transaction Hash:
                  </h3>
                  <p className="font-mono break-all">
                    <CopyToClipboard text={transactionDetails.transactionHash}>
                      {transactionDetails.transactionHash}
                    </CopyToClipboard>
                  </p>
                </div>
              )}

              <div className="flex flex-col lg:flex-row gap-1 lg:gap-2 items-start lg:items-center  border-b border-border-light p-3 text-sm">
                <h3 className="text-muted-foreground w-full max-w-[170px]">
                  Transaction Fee:
                </h3>
                <p className="font-mono">{txnFee}</p>
              </div>

              <div className="flex flex-col lg:flex-row gap-1 lg:gap-2 items-start lg:items-start w-full border-b border-border-light p-3 text-sm">
                <h3 className="text-muted-foreground w-full max-w-[170px]">
                  Transaction Type:
                </h3>
                <div className="font-mono flex flex-col lg:flex-row items-start lg:items-center justify-between w-full">
                  <div className="flex flex-col">
                    <p className="text-muted-foreground">{txnType}</p>
                    <p className="text-xs text-muted pt-1">
                      {getTransactionInfo(transactionDetails.txnType || -1)
                        ?.desc || ""}
                    </p>
                  </div>
                </div>
              </div>

              {transactionDetails.publicKey && (
                <div className="flex flex-col lg:flex-row gap-2 items-start lg:items-center border-b border-border-light p-3 text-sm">
                  <h3 className="text-muted-foreground w-full max-w-[170px]">
                    Sender:
                  </h3>

                  <p className="font-mono break-all">
                    <code className="text-xs block lg:inline-block lg:border border-border-light lg:px-2 rounded-md py-1">
                      <CopyToClipboard text={transactionDetails.publicKey}>
                        {transactionDetails.publicKey}
                      </CopyToClipboard>
                    </code>
                  </p>
                </div>
              )}

              {receiverPublicKey && (
                <div className="flex flex-col lg:flex-row gap-2 items-start lg:items-center border-b border-border-light p-3 text-sm">
                  <h3 className="text-muted-foreground w-full max-w-[170px]">
                    Recipient:
                  </h3>

                  <p className="font-mono break-all">
                    <code className="text-xs block lg:inline-block lg:border border-border-light lg:px-2 rounded-md py-1">
                      <CopyToClipboard text={receiverPublicKey}>
                        {receiverPublicKey}
                      </CopyToClipboard>
                    </code>
                  </p>
                </div>
              )}

              <div className="flex flex-col lg:flex-row gap-2 items-start w-full p-3 text-sm">
                <h3 className="text-muted-foreground w-full max-w-[170px]">
                  Raw Output:
                </h3>

                <div className="font-mono w-full">
                  <div
                    className="cursor-pointer"
                    onClick={() => setShowRawJSON((prev) => !prev)}
                  >
                    {showRawJSON ? (
                      <pre>[-] HIDE JSON OBJECT</pre>
                    ) : (
                      <pre>[+] SHOW JSON OBJECT</pre>
                    )}
                  </div>

                  <div>
                    {showRawJSON && (
                      <div className="border border-border-light rounded-md w-full flex-none mt-4">
                        <pre className="break-all whitespace-pre-wrap w-full p-4">
                          {JSON.stringify(transactionDetails, null, 4)}
                        </pre>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="mb-14">
          <h3 className="flex w-full justify-between gap-2 items-center">
            <div className="flex gap-2">
              <ScrollTextIcon />
              Raw Output
            </div>

            <CopyToClipboard
              showIcon={false}
              showToast={true}
              text={JSON.stringify(transactionDetails, null, 4)}
              toastText={
                "The transaction details JSON has been successfully copied to your clipboard"
              }
            >
              <Button variant="outline" size="sm">
                Copy code
              </Button>
            </CopyToClipboard>
          </h3>

          {loadingTransaction || !transactionDetails ? (
            <Skeleton className="mt-4 w-full h-[500px]" />
          ) : (
            <div className="border border-border rounded-2xl overflow-hidden mt-4 max-h-[600px] custom-scrollbar">
              <div className="flex flex-col lg:flex-row justify-between">
                <div className="w-full text-xs custom-scrollbar leading-5 max-h-[600px] cursor-text">
                  <CodeHighlighter json={prettifyJSON(transactionDetails)} />
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </main>
  );
};

export default TransactionDetail;
