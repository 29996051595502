import { Metric } from "../ui/metric";
import { useQuery } from "@apollo/client";
import { ProfilePortfolioValueStatByPublicKeyDocument } from "../../graphql/codegen/graphql";
import { client } from "../../graphql/client";
import { range } from "../../utils/helpers";
import { Skeleton } from "@/components/ui/skeleton";
import { desoNanosToUSD, formatDecimalValue } from "../../utils/currency";
import { useContext } from "react";
import { MarketDataContext } from "../../contexts/market-data";

interface EarningsBreakdownProps {
  publicKey: string;
}

const SKELETON_NUM_OF_CARDS = 8;

const EarningsBreakdown = ({ publicKey }: EarningsBreakdownProps) => {
  const { marketData, loading: loadingMarketData } =
    useContext(MarketDataContext);

  const { loading: loadingEarnings, data: userEarnings } = useQuery(
    ProfilePortfolioValueStatByPublicKeyDocument,
    {
      client,
      variables: {
        publicKey,
      },
    },
  );

  const earningStats = userEarnings?.profileEarningsBreakdownStatByPublicKey;

  return (
    <div>
      <div className="flex items-center px-4 mt-4">
        <h3 className="mb-4 text-xs flex items-center">
          Breakdown by Asset Type
        </h3>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 p-4 pt-0">
        {loadingEarnings || loadingMarketData || !marketData ? (
          range(SKELETON_NUM_OF_CARDS).map((_, i) => (
            <Skeleton className="h-[80px]" key={i} />
          ))
        ) : (
          <>
            <Metric
              value={formatDecimalValue(earningStats?.diamondsGivenCount || 0)}
              label="Diamonds Given"
              tooltip="The number of diamonds given to other users"
            />
            <Metric
              value={formatDecimalValue(
                earningStats?.diamondsReceivedCount || 0,
              )}
              label="Diamonds Received"
              tooltip="The number of diamonds received from other users"
            />
            <Metric
              value={formatDecimalValue(earningStats?.ccBuyCount || 0)}
              caption={`${formatDecimalValue(
                desoNanosToUSD(
                  earningStats?.ccBuyAmountNanos || 0,
                  marketData.current_price.usd * 100,
                ),
              )} USD`}
              label="Creator Coin Buyers"
              tooltip="The number of users who bought this creator coin. Does not include sells."
            />
            <Metric
              value={formatDecimalValue(earningStats?.ccSellCount || 0)}
              caption={`${formatDecimalValue(
                desoNanosToUSD(
                  earningStats?.ccSellAmountNanos || 0,
                  marketData.current_price.usd * 100,
                ),
                2,
                2,
              )} USD`}
              label="Creator Coin Sellers"
              tooltip="The number of users who sold this creator coin. Does not include buys."
            />
            <Metric
              value={formatDecimalValue(earningStats?.nftSellCount || 0)}
              caption={`${formatDecimalValue(
                desoNanosToUSD(
                  earningStats?.nftSellAmountNanos || 0,
                  marketData.current_price.usd * 100,
                ),
                2,
                2,
              )} USD`}
              label="NFT Sales"
              tooltip="The number of NFTs sold by this user."
            />
            <Metric
              value={formatDecimalValue(earningStats?.nftBuyCount || 0)}
              caption={`${formatDecimalValue(
                desoNanosToUSD(
                  earningStats?.nftBuyAmountNanos || 0,
                  marketData.current_price.usd * 100,
                ),
                2,
                2,
              )} USD`}
              label="NFT Buys"
              tooltip="The number of NFTs bought by this user. Includes auctions & direct purchases."
            />
            <Metric
              value={formatDecimalValue(earningStats?.tokenBuyTradeCount || 0)}
              caption={`${formatDecimalValue(
                desoNanosToUSD(
                  earningStats?.tokenBuyOrderNanosFilled || 0,
                  marketData.current_price.usd * 100,
                ),
                2,
                2,
              )} USD`}
              label="DEX Trades (Buys)"
              tooltip="The number of on-chain buy market & limit orders placed."
            />
            <Metric
              value={formatDecimalValue(earningStats?.tokenSellTradeCount || 0)}
              caption={`${formatDecimalValue(
                desoNanosToUSD(
                  earningStats?.tokenSellOrderNanosFillede || 0,
                  marketData.current_price.usd * 100,
                ),
                2,
                2,
              )} USD`}
              label="DEX Trades (Sell)"
              tooltip="The number of on-chain sell market & limit orders placed."
            />
          </>
        )}
      </div>
    </div>
  );
};

export default EarningsBreakdown;
