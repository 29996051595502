import { Link } from "react-router-dom";

interface UserLinkProps {
  username?: string;
  pubKey: string;
  className?: string;
  children: JSX.Element | Array<JSX.Element> | string;
}

const UserLink = ({
  username,
  pubKey,
  className = "",
  children,
}: UserLinkProps) => {
  return (
    <Link to={`/u/${username || pubKey}`} className={className}>
      {children}
    </Link>
  );
};

export default UserLink;
