import { createContext } from "react";
import { CoreAccountFieldsFragment } from "../graphql/codegen/graphql";

export interface ActiveAccountContextType {
  account: CoreAccountFieldsFragment | null;
  accountLoading: boolean;
}

export const ActiveAccountContext = createContext<ActiveAccountContextType>({
  account: null,
  accountLoading: false,
});
