import { createContext } from "react";
import { CoinGeckoMarketData } from "../utils/coin-gecko";

export interface MarketDataContextType {
  marketData: CoinGeckoMarketData | null;
  exchangeRate: number;
  loading: boolean;
}

export const MarketDataContext = createContext<MarketDataContextType>({
  marketData: null,
  exchangeRate: 0,
  loading: false,
});
