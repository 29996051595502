import {
  desoNanosToDeso,
  desoNanosToUSD,
  formatDecimalValue,
  formatUSD,
} from "../../utils/currency";
import { useContext } from "react";
import { MarketDataContext } from "../../contexts/market-data";
import * as React from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib";

interface UserDesoBalanceProps {
  balanceNanos: string;
  loading?: boolean;
  className?: string;
}

const UserDesoBalance = ({
  balanceNanos,
  loading = false,
  className = "text-sm",
}: UserDesoBalanceProps) => {
  const { marketData, loading: loadingMarketData } =
    useContext(MarketDataContext);

  return loadingMarketData || !marketData || loading ? (
    <Skeleton className="w-full mb-3 h-5" />
  ) : (
    <div className={cn(`flex items-center mb-0 text-muted`, className)}>
      <div className="mr-1 text-center">
        <img
          src="/assets/img/logo-deso-mark.svg"
          className="w-[12px] mx-1"
          alt="DESO"
        />
      </div>

      <div>
        {formatDecimalValue(desoNanosToDeso(balanceNanos || 0))}
        <span className="ml-1 text-xs">DESO</span>

        <span className="mx-1"> ≈ </span>
        {formatUSD(
          desoNanosToUSD(balanceNanos || 0, marketData.current_price.usd * 100),
        )}
        <span className="ml-1 text-xs">USD</span>
      </div>
    </div>
  );
};

export default UserDesoBalance;
